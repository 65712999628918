<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  *transloco="let t; read: 'layout.vertical.classy'"
  class="dark bg-gray-900 print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.default"
  [opened]="!isScreenSmall">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <div class="flex items-center w-full p-4 pl-6">
      <!-- Logo -->
      <div class="flex items-center justify-center">
        <img
          class="w-8"
          src="assets/images/logo/logo_blue_bg_transparent_big.svg">
        </div>
        <!-- Components -->
        <div class="flex items-center ml-auto">
          <notifications></notifications>
          <user [showAvatar]="false"></user>
        </div>
      </div>
      <!-- User -->
      <div class="flex flex-col items-center w-full px-4 pt-4">
        <div class="relative w-24 h-24">
          @if (user?.photoUrl) {
            <img
              class="w-full h-full rounded-full"
              [src]="user?.photoUrl"
              alt="User avatar">
          }
          @else {
            <mat-icon
              class="icon-size-24"
            [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
          }
        </div>
        <!-- Merchant selector -->
        <div class="flex flex-col items-center justify-center w-full my-2"
          matRipple
          [matMenuTriggerFor]="merchantMenu">
          <div class="flex self-start w-full">
            <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-semibold text-primary text-2xl pl-7 cursor-pointer">
              {{merchant?.displayName}}
            </div>
            <div class="flex items-center justify-center pl-2">
              <mat-icon
                [color]="'primary'"
                class="icon-size-5"
              [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
            </div>
          </div>
          <mat-menu
            #merchantMenu="matMenu"
            [xPosition]="'before'">
            @if (merchant) {
              <a
                href="https://{{merchant?.name}}.{{domain}}"
                target="_blank"
                mat-menu-item>
                <mat-icon
                [svgIcon]="'heroicons_solid:link'"></mat-icon>
                {{ t('visit-website') }}
              </a>
            }
            <button
              mat-menu-item
              [matMenuTriggerFor]="merchantsMenu">
              <mat-icon
              [svgIcon]="'heroicons_solid:arrows-right-left'"></mat-icon>
              {{ t('switch-merchant') }}
            </button>
            <mat-divider></mat-divider>
            <button
              mat-menu-item
              [routerLink]="['/onboarding/start']">
              <mat-icon
                [color]="'primary'"
              [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
              {{ t('create-merchant') }}
            </button>
            <button
              mat-menu-item
              [routerLink]="['/settings/general']">
              <mat-icon
                [color]="'warn'"
              [svgIcon]="'heroicons_solid:trash'"></mat-icon>
              {{ t('delete-merchant') }}
            </button>
          </mat-menu>
          <mat-menu
            #merchantsMenu="matMenu"
            [xPosition]="'before'">
            @for (merchant of merchants; track merchant._id) {
              <button
                mat-menu-item
                (click)="changeMerchant(merchant)">
                {{ merchant.displayName }}
              </button>
            }
          </mat-menu>
        </div>
        <!-- User name -->
        <div class="flex flex-col items-center justify-center w-full mt-0">
          <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
            {{user?.name}}
          </div>
          <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
            {{user?.email}}
          </div>
        </div>
      </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
      <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12 overflow-hidden">
        <img
          class="max-w-36"
          src="assets/images/logo/all_blue_bg_transparent.svg">
        </div>
      </ng-container>
    </fuse-vertical-navigation>

    <!-- Print the version number in small font in the bottom in a static position -->
    <div class="fixed bottom-0 right-0 z-50 p-2 text-xs text-secondary print:hidden">
      <span>13.5.1</span>
    </div>

    <!-- Wrapper -->
    <div class="flex flex-col flex-auto w-full min-w-0">

      <!-- Header -->
      <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
        *transloco="let t; read: 'layout.vertical.classy'">
      <!-- Navigation toggle button -->
      <button
        mat-icon-button
        (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
      </button>
      <!-- Components -->
      <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
        <!-- Visit website -->
        <button mat-icon-button
          class="sm:hidden"
          (click)="visitWebsite()">
          <mat-icon svgIcon="mat_solid:open_in_browser"></mat-icon>
        </button>
        <button mat-stroked-button
          color="primary"
          class="hidden sm:block"
          (click)="visitWebsite()">
          {{ t('visit-website') }}
        </button>
        <languages></languages>
        <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
        <app-book class="ml-4"></app-book>
        <!--<search [appearance]="'bar'"></search>-->
        <!--<messages></messages>-->
      </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
      Otherwise, layout changes won't be registered and the view won't be updated! -->
      @if (true) {
        <router-outlet #outlet="outlet" (activate)="onActivate($event, outlet)"></router-outlet>
      }
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
    <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
  </div>-->

</div>

