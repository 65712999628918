import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';

/**
 * MerchantGuard is a guard that checks the authentication status of a merchant before allowing access to a route.
 * It implements the CanActivateFn or CanActivateChildFn interface.
 *
 * @param route - The activated route snapshot.
 * @param state - The router state snapshot.
 * @returns A boolean or an Observable that emits a boolean indicating whether the merchant is authenticated and allowed access to the route.
 */
export const MerchantGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);
    const merchantService: MerchantService = inject(MerchantService);
    const authService: AuthService = inject(AuthService);
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;

    // Check the authentication status
    return merchantService.merchant$.pipe(
        switchMap((merchant: Merchant) => {
            // If there is no merchant...
            if (!merchant) {
                authService.signOut(true)
                    .then(() => {
                        // Redirect to the sign-in page
                        router.navigate(['sign-in'], { queryParams: { redirectUrl } });

                        // Prevent the access
                        return of(false);
                    });
            }
            // Allow the access
            return of(true);
        })
    );
};
