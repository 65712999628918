import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';

/**
 * NoAuthGuard is a guard that prevents access to routes for authenticated users.
 * If the user is authenticated, it redirects to the specified URL and returns false.
 * If the user is not authenticated, it allows access and returns true.
 *
 * @param route - The activated route snapshot.
 * @param state - The router state snapshot.
 * @returns A boolean indicating whether to allow or prevent access to the route.
 */
export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);

    // Check the authentication status
    return authService.check().pipe(
        switchMap((authenticated) =>
        {
            // If the user is authenticated...
            if (authenticated) {
                // Redirect to the specified URL
                router.navigate(['']);

                // Prevent the access
                return of(false);
            }

            // Allow the access
            return of(true);
        }),
    );
};
