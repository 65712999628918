import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Translation, TranslocoLoader } from '@jsverse/transloco';

/**
 * Transloco HTTP loader
 *
 * This class implements the `TranslocoLoader` interface and provides a way to load translations
 * from a JSON file using the Angular `HttpClient`.
 */
@Injectable({
    providedIn: 'root'
})
export class TranslocoHttpLoader implements TranslocoLoader {
    /**
     * Constructor
     *
     * @param _httpClient Http client
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get translation
     *
     * @param lang Language
     * @returns Translation
     */
    getTranslation(lang: string): Observable<Translation> {
        return this._httpClient.get<Translation>(`./assets/i18n/${lang}.json`);
    }
}
