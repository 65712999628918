import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { User as FirebaseUser } from 'firebase/auth';
import { UserService } from 'app/core/user/user.service';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIconButton } from '@angular/material/button';
import { TranslocoModule } from '@jsverse/transloco';


/**
 * User component
 *
 * Displays the current user's information and allows the user to sign out.
 */
@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [TranslocoModule, MatIconButton, MatMenuTrigger, MatIcon, NgClass, MatMenu, MatMenuItem, MatDivider, RouterLink]
})
export class UserComponent implements OnInit, OnDestroy
{
    /**
     * Whether to show the user's avatar or not
     */
    @Input() showAvatar: boolean = true;

    /**
     * The current user
     */
    user: User;

    /**
     * Subject that emits when the component is destroyed
     */
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     *
     * @param _changeDetectorRef The change detector reference
     * @param _router The router
     * @param _userService The user service
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initializes the component and subscribes to user changes.
     * 
     * @returns void
     */
    ngOnInit(): void
    {
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(async (user: FirebaseUser) => {
                if (user) {
                    this.user = await this._userService.getQartUser();
                    // Mark for check
                    this._changeDetectorRef.markForCheck();
                }
            });
    }

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * Unsubscribes from all subscriptions.
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign out the current user
     */
    signOut(): void
    {
        this._router.navigate(['/sign-out']);
    }
}
