import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { MerchantService } from '@core/merchant/merchant.service';
import { SettingsService } from '@core/settings/settings.service';
import { EmployeeService } from '@core/employee/employee.service';
import { Navigation } from '@core/navigation/navigation.types';
import { Merchant } from '@core/merchant/merchant.types';
import { Settings } from '@core/settings/settings.types';
import { Employee } from '@core/employee/employee.types';
import { AdminInvitationService } from '@core/admin-invitation/admin-invitation.service';
import { AdminInvitation } from '@core/admin-invitation/admin-invitation.types';
import { CategoryService } from '@core/category/category.service';
import { Category } from '@core/category/category.types';
import { ProductService } from '@core/product/product.service';
import { ConfigurationService } from '@core/configuration/configuration.service';
import { Configuration } from '@core/configuration/configuration.types';
import { PermissionService } from '@core/permission/permission.service';
import { AccessControl } from '@core/permission/access-control.types';

/**
 * The InitialDataResolver class implements the Resolve interface to resolve data for the application before it is displayed.
 * It resolves data from multiple API endpoint calls using the forkJoin operator.
 */
@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>
{
    /**
     * AppResolvers constructor function.
     * 
     * @param _navigationService - The NavigationService instance.
     * @param _merchantService - The MerchantService instance.
     * @param _settingsService - The SettingsService instance.
     * @param _categoryService - The CategoryService instance.
     * @param _employeeService - The EmployeeService instance.
     * @param _productService - The ProductService instance.
     * @param _adminInvitationService - The AdminInvitationService instance.
     * @param _permissionService - The PermissionService instance.
     * @param _configurationService - The ConfigurationService instance.
     */
    constructor(
        private _navigationService: NavigationService,
        private _merchantService: MerchantService,
        private _settingsService: SettingsService,
        private _categoryService: CategoryService,
        private _employeeService: EmployeeService,
        private _productService: ProductService,
        private _adminInvitationService: AdminInvitationService,
        private _permissionService: PermissionService,
        private _configurationService: ConfigurationService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolves data for the application before it is displayed.
     * @param route - The activated route snapshot.
     * @param state - The router state snapshot.
     * @returns An observable that emits an array of resolved data.
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<[Merchant[], Settings, Category[], Employee[], string[], AdminInvitation[], AccessControl, Configuration, Navigation]>
    {
        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin([
            this._merchantService.getMerchants(),
            this._settingsService.getSettings(),
            this._categoryService.getCategories(),
            this._employeeService.getEmployees(),
            this._productService.getProductTags(),
            this._adminInvitationService.getAdminInvitations(),
            this._permissionService.getAccessControl(),
            this._configurationService.getConfiguration(),
            this._navigationService.get(),
        ]);
    }
}
