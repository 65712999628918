import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { UserService } from '@core/user/user.service';
import { LangDefinition, TranslocoService } from '@jsverse/transloco';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { CookieService } from 'ngx-cookie-service';
import { NgTemplateOutlet } from '@angular/common';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIconButton } from '@angular/material/button';


/**
 * Component for displaying and changing the language of the application.
 */
@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages',
    standalone: true,
    imports: [MatIconButton, MatMenuTrigger, NgTemplateOutlet, MatMenu, MatMenuItem]
})
export class LanguagesComponent implements OnInit, OnDestroy {
    /**
     * The available languages that can be selected.
     */
    availableLangs: LangDefinition[];

    /**
     * The currently active language.
     */
    activeLang: string;

    /**
     * The country ISO codes for languages for flags.
     */
    flagCodes: any;

    /**
     * The list of language codes and locales.
     */
    private _localeList: { code: string; locale: string }[];

    /**
     * Constructor
     *
     * @param _translocoService The Transloco service for translating the application.
     * @param _cookieService The cookie service for setting the user's language preference.
     * @param _userService The user service for updating the user's language preference.
     * @param _translocoLocaleService The Transloco locale service for setting the locale.
     * @param _dateAdapter The date adapter for setting the locale.
     */
    constructor(
        private _translocoService: TranslocoService,
        private _cookieService: CookieService,
        private _userService: UserService,
        private _translocoLocaleService: TranslocoLocaleService,
        private _dateAdapter: DateAdapter<any>,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initializes the component.
     * 
     * Gets the available languages from Transloco, sets the country ISO codes for languages for flags and locales,
     * and subscribes to language changes.
     */
    ngOnInit(): void {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs() as LangDefinition[];

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {

            // Get the active lang
            this.activeLang = activeLang;
        });

        // Set the country iso codes for languages for flags
        this.flagCodes = {
            'en': 'us',
            'fr': 'fr',
            'de': 'de'
        };

        // Set the country iso codes for languages for the locales
        this._localeList = [
            { code: 'en', locale: 'en-US' },
            { code: 'de', locale: 'de-DE' },
            { code: 'fr', locale: 'fr-FR' }
        ];
    }

    /**
     * Cleans up the component.
     */
    ngOnDestroy(): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sets the active language.
     * 
     * @param lang The language to set as active.
     */
    setActiveLang(lang: string): void {
        // Verify that the language is available
        if (!this.availableLangs.map(l => l.id).includes(lang)) {
            lang = 'fr';
        }

        // Verify that the language is not the same as the current one
        const activeLanguage: string = this._translocoService.getActiveLang();
        if (activeLanguage !== lang) {
            // Set the active lang
            this._translocoService.setActiveLang(lang);
        }

        // Update the locale
        const userLocale: string = this._localeList.find(
            (o) => o.code === lang
        ).locale;
        this._translocoLocaleService.setLocale(userLocale);
        this._dateAdapter.setLocale(userLocale);

        // Save the user preference
        localStorage.setItem('lang', lang);

        // Save it in a cookie as well so that it can be re-used across subdomains)
        this._cookieService.set('lang', lang, {
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            path: '/',
            domain: 'qart.shop',
            secure: true,
            sameSite: 'Lax'
        });

        // Push the language to GTM
        if (window["dataLayer"]) {
            window["dataLayer"].push({
                language: lang
            });
        }

        this._userService.updateUser();
    }

    /**
     * Returns the ID or index of an item for ngFor loops.
     * 
     * @param index The index of the item.
     * @param item The item itself.
     * @returns The ID or index of the item.
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

}
