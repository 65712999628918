import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { CalendarService } from '@core/calendar/calendar.service';
import { EmployeeService } from '@core/employee/employee.service';
import { Employee } from '@core/employee/employee.types';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { OrderService } from '@core/order/order.service';
import { Order } from '@core/order/order.types';
import { QartOrder } from '@core/order/qart-order.types';
import { ProductOrder } from '@core/product/product-order.types';
import { ProductService } from '@core/product/product.service';
import { Attribute, Product, ProductType, VariantItem } from '@core/product/product.types';
import { SettingsService } from '@core/settings/settings.service';
import { Settings } from '@core/settings/settings.types';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, CalendarOptions } from '@fullcalendar/core'; // include this line
import interactionPlugin from '@fullcalendar/interaction';
import { FuseDrawerComponent, FuseDrawerService } from '@fuse/components/drawer';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';
import moment from 'moment-timezone';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { SharedFullCalendarModule } from '@shared/fullcalendar.module';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { FormsModule } from '@angular/forms';

/**
 * The number of months ahead to display in the book component.
 */
const numberOfMonthsAhead: number = 1;

/**
 * Component for booking appointments.
 *
 * @remarks
 * This component displays a calendar and allows users to book appointments with employees.
 *
 * @example
 * <app-book></app-book>
 */
@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styles: [
    `
        app-book {
            position: static;
            display: block;
            flex: none;
            width: auto;
        }
    `
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    MatListModule,
    TranslocoLocaleModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatMomentDateModule,
    MatRippleModule,
    RouterModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    SharedFullCalendarModule,
    FuseDrawerComponent
  ]
})
export class BookComponent implements OnInit {

  /**
   * The full calendar component.
   */
  @ViewChild('fullCalendar') fullCalendar: FullCalendarComponent;

  /**
   * The merchant.
   */
  merchant: Merchant;

  /**
   * The settings.
   */
  settings: Settings;

  /**
   * The calendar options.
   */
  calendarOptions: CalendarOptions;

  /**
   * The employees.
   */
  employees: Employee[];

  /**
   * The events.
   */
  events: any[];

  /**
   * All event sources.
   */
  allEventSources: any[];

  /**
   * Selected event sources.
   */
  selectedEventSources: any[];

  /**
   * Whether the calendar is loading.
   */
  isLoadingCalendar: boolean;

  /**
   * The services.
   */
  services: Product[];

  /**
   * The attributes.
   */
  attributes: Attribute[];

  /**
   * The product type.
   */
  ProductType = ProductType;

  /**
   * The current step.
   */
  currentStep: string = 'selectService';

  /**
   * Available times.
   */
  availableTimes: any;

  /**
   * Employee availability.
   */
  employeeAvailability: any;

  /**
   * Available employees.
   */
  availableEmployees: Employee[];

  /**
   * The customer name.
   */
  customerName: string;

  /**
   * The customer email.
   */
  customerEmail: string;

  /**
   * The selected date.
   */
  selectedDate: Date;

  /**
   * The selected day.
   */
  selectedDay: any;

  /**
   * The selected time slot.
   */
  selectedTimeSlot: moment.Moment;

  /**
   * A flag indicating whether the selected day has available time slots.
   */
  selectedDayHasAvailableTime: boolean;

  /**
   * The minimum calendar date.
   */
  minCalendarDate: Date;

  /**
   * The selected service.
   */
  selectedService: Product;

  /**
   * The selected variants.
   */
  selectedVariants: any;

  /**
   * The selected variant item.
   */
  selectedVariantItem: VariantItem;

  /**
   * The selected employee.
   */
  selectedEmployee: Employee;

  /**
   * The selected service time slot object.
   */
  selectedServiceTimeSlotObject: any;

  /**
   * Whether to show a new event.
   */
  showNewEvent: boolean;

  /**
   * The displayed date.
   */
  displayedDate: Date;

  /**
   * Whether to show the calendar.
   */
  showCalendar: boolean;

  /**
   * Whether the day availability is loading.
   */
  loadingDayAvailability: boolean;

  /**
   * The template data.
   */
  tpl: {
    displayComponent: boolean,
    hasPhoto: { [productId: string]: boolean };
    firstPhotoUrl: { [productId: string]: string };
    currency: string;
  };

  /**
   * Whether the order is being saved.
   */
  savingOrder: boolean = false;

  /**
   * The unsubscribe subject.
   */
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * The transloco read string.
   */
  private _translocoRead: string = "pages.admin.book";

  /**
   * The matching aliases.
   */
  private _matchingAliases: string[] = [];

  /**
   * The first fetched date.
   */
  private _firstFetchedDate: Date;

  /**
   * The last fetched date.
   */
  private _lastFetchedDate: Date;

  /**
   * Whether the component is enabled.
   */
  private _componentEnabled: boolean = false;

  /**
   * Constructor
   *
   * @param _merchantService - The merchant service.
   * @param _settingsService - The settings service.
   * @param _employeeService - The employee service.
   * @param _calendarService - The calendar service.
   * @param _productService - The product service.
   * @param _orderService - The order service.
   * @param _changeDetectorRef - The change detector reference.
   * @param _fuseMediaWatcherService - The media watcher service.
   * @param _translocoService - The transloco service.
   * @param _fuseConfirmationService - The confirmation service.
   * @param _datePipe - The date pipe.
   * @param _dialog - The dialog.
   * @param _router - The router.
   * @param _fuseDrawerService - The drawer service.
   */
  constructor(
    private _merchantService: MerchantService,
    private _settingsService: SettingsService,
    private _employeeService: EmployeeService,
    private _calendarService: CalendarService,
    private _productService: ProductService,
    private _orderService: OrderService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _translocoService: TranslocoService,
    private _fuseConfirmationService: FuseConfirmationService,
    private _datePipe: DatePipe,
    public _dialog: MatDialog,
    private _router: Router,
    private _fuseDrawerService: FuseDrawerService,
  ) {
    const name = Calendar.name; // add this line in your constructor
    this.availableTimes = [];
    this.employeeAvailability = {};
    this.availableEmployees = [];
    this.selectedService = null;
    this.selectedVariants = {};
    this.selectedVariantItem = null;
    this.selectedEmployee = null;
    this.selectedDate = new Date();
    this.selectedDate.setHours(0, 0, 0, 0);
    this.selectedDayHasAvailableTime = false;
    this.customerName = null;
    this.customerEmail = null;
    this.minCalendarDate = new Date();
    this.displayedDate = new Date(this.minCalendarDate);
    this.allEventSources = [];
    this.selectedEventSources = [];
    this.showNewEvent = false;
    this.showCalendar = false;
    this.loadingDayAvailability = false;
    this.tpl = {
      displayComponent: false,
      hasPhoto: {},
      firstPhotoUrl: {},
      currency: 'EUR'
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Initializes the component and subscribes to various services to get data.
   */
  ngOnInit(): void {

    // Get the merchant
    this._merchantService.merchant$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((merchant: Merchant) => {
        this.merchant = merchant;
        this._checkIfEnabled();
        this._reloadServices();
      });

    // Get the settings
    this._settingsService.settings$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings: Settings) => {
        this.settings = settings;
        this._checkIfEnabled();
        this._updateTemplateData();
        this._init();
      });

    // Get the employees
    this._employeeService.employees$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((employees: Employee[]) => {
        this.employees = employees;
        this._checkIfEnabled();
        this._init();
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        this._matchingAliases = matchingAliases;
        //this._renderCalendar();
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * Unsubscribes from all subscriptions to prevent memory leaks.
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Checks if the component is enabled based on the availability of required data and settings.
   * @returns void
   */
  private _checkIfEnabled(): void {
    const missingObject: boolean = !this.merchant || !this.settings || !this.employees || !this.services;
    const googleCalendarConnected: boolean = this.settings?.googleCalendarConnected ?? false;
    const hasServices: boolean = this.services?.length > 0;
    this._componentEnabled = !missingObject && googleCalendarConnected && hasServices;
  }

  /**
   * Reloads the services by fetching them from the ProductService.
   * Updates the services array, checks if they are enabled, updates the template data and initializes the component.
   * Finally, marks the component for check.
   */
  private _reloadServices(): void {
    this.services = null;
    // Get the services
    this._productService.getProducts({
      'type': ProductType.Service
    })
      .subscribe((services: Product[]) => {
        this.services = services;
        this._checkIfEnabled();
        this._updateTemplateData();
        this._init();
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * Initializes the component.
   * If the component is not enabled, it returns early.
   * It creates an event source for each employee and adds it to the `allEventSources` array.
   * It sets the `calendarOptions` object with various options for the calendar.
   * It sets the `resources` property of the `calendarOptions` object to an array of resources.
   * It calls the `_renderCalendar` method to render the calendar.
   */
  private _init() {
    if (!this._componentEnabled) {
      return;
    }
    this.employees.forEach((employee, index) => {
      const colorIdx = index % 10;
      this.allEventSources.push({
        id: employee._id,
        events: (info, successCallback, failureCallback) => {
          this._calendarService.getCalendarEventsFromEmployee(
            employee,
            info.start.toISOString(),
            info.end.toISOString()
          )
            .subscribe((events: any) => successCallback(this._formatEvents(events, employee._id, employee.googleCalendarId)));
        },
        className: `fullcalendar-event color-${colorIdx}`,
        textColor: 'black',
        editable: false
      });
    });
    this.calendarOptions = {
      plugins: [interactionPlugin, listPlugin, timeGridPlugin],
      slotMinTime: this._getCalendarMinTime(),
      slotMaxTime: this._getCalendarMaxTime(),
      businessHours: this._getMerchantBusinessHours(),
      themeSystem: 'standard',
      nowIndicator: true,
      weekends: true,
      locale: 'locale',
      editable: false,
      handleWindowResize: true,
      height: 'auto',
      loading: (isLoading) => this.loadingCalendar(isLoading)
    };
    if (!('sm' in this._matchingAliases) && this.employees.length > 0) {
      this.selectedEventSources.push(this.allEventSources[0]);
    } else {
      this.selectedEventSources = this.allEventSources;
    }
    this.calendarOptions.eventSources = this.selectedEventSources;
    this._renderCalendar();
  }

  /**
   * Renders the calendar based on the component's properties.
   * If the component is disabled, it returns without rendering.
   * If the merchant property is defined, it sets the calendar header and title format based on the screen size.
   */
  private _renderCalendar() {
    if (this.merchant !== undefined) {
      if (this._matchingAliases.includes('sm')) {
        // Laptops and wide screens
        this.calendarOptions.headerToolbar = {
          start: 'title',
          center: 'prev,next today',
          end: 'timeGridWeek,listMonth'
        };
        this.calendarOptions.titleFormat = {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        };
        this.calendarOptions.initialView = 'timeGridWeek';
      } else {
        // Mobile devices
        this.calendarOptions.headerToolbar = {
          start: 'title',
          end: 'prev,next'
        };
        this.calendarOptions.titleFormat = {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        };
        this.calendarOptions.initialView = 'listMonth';
      }
    }
  }

  /**
   * Checks if the given object has a photo.
   * @param obj - The object to check.
   * @returns True if the object has a photo, false otherwise.
   */
  private _hasPhoto(obj: Product | Employee): boolean {
    return (obj.photos && obj.photos.length > 0);
  }

  /**
   * Updates the template data based on the current component state and settings.
   * @remarks
   * This method updates the displayComponent, currency, hasPhoto, and firstPhotoUrl properties of the template.
   */
  private _updateTemplateData() {
    if (!this.tpl || !this._componentEnabled || !this.settings) {
      return;
    }
    this.tpl.displayComponent = this.services.length > 0;
    this.tpl.currency = this.settings.currency;
    for (let service of this.services) {
      this.tpl.hasPhoto[service._id] = this._hasPhoto(service);
      const photoUrls: string[] = this._productService.getPhotoUrls(service);
      this.tpl.firstPhotoUrl[service._id] = photoUrls.length > 0 ? photoUrls[0] : '';
    }
    // Save the availability of each employee per day
    for (const employee of this.availableEmployees) {
      if (!this.employeeAvailability[employee._id]) {
        this.employeeAvailability[employee._id] = {};
      }
      for (const dayAvl of this.availableTimes) {
        if (!this.employeeAvailability[employee._id][dayAvl.date.getTime()]) {
          this.employeeAvailability[employee._id][dayAvl.date.getTime()] = dayAvl.availability
            .filter(avl => avl.employees.map(e => e.id).includes(employee._id));
        }
      }
    }
    this.employeeAvailability['any'] = {};
    for (const dayAvl of this.availableTimes) {
      if (!this.employeeAvailability['any'][dayAvl.date.getTime()]) {
        this.employeeAvailability['any'][dayAvl.date.getTime()] = dayAvl.availability;
      }
    }
    // Format the time slots
    for (const employeeId of Object.keys(this.employeeAvailability)) {
      for (const timestamp of Object.keys(this.employeeAvailability[employeeId])) {
        this.employeeAvailability[employeeId][timestamp].forEach(timeSlot => {
          const startTime: moment.Moment = moment(timeSlot.time)
              .tz(this.settings.timezone);
          let endTime: moment.Moment = moment(timeSlot.time)
              .add(this.selectedVariantItem.duration, 'minutes')
              .tz(this.settings.timezone);
          // Convert to string to avoid memory leak because of the translocoDate pipe
          timeSlot['displayedStartTime'] = this._formatTimeSlot(startTime);
          timeSlot['displayedEndTime'] = this._formatTimeSlot(endTime);
        });
      }
    }
  }

  /**
   * Returns the earliest opening time of the merchant's business hours in the format 'HH:mm:ss'.
   * If the merchant has no opening hours, returns '00:00:00'.
   * @returns {string} The earliest opening time of the merchant's business hours.
   */
  private _getCalendarMinTime(): string {
    let minTimeHours = 24;
    let minTimeMinutes = 60;
    if (this.merchant.openingHours) {
      for (const day of this.merchant.openingHours.days) {
        if (day.open) {
          const firstSlot = day.slots[0];
          let fromMinutes, fromHours, fromMatch;
          fromMatch = firstSlot.from.selected.match(/([0-1]?[0-9]):([0-5][0-9]) (am|pm)/);
          fromHours = fromMatch[3] === 'am' ? parseInt(fromMatch[1]) : parseInt(fromMatch[1]) + 12;
          fromMinutes = parseInt(fromMatch[2]);
          if (fromHours < minTimeHours) {
            minTimeHours = fromHours;
            minTimeMinutes = fromMinutes;
          } else if ((fromHours == minTimeHours) && (fromMinutes < minTimeHours)) {
            minTimeMinutes = fromMinutes;
          }
        }
      }
    }
    if ((minTimeHours === 24) && (minTimeMinutes === 60)) {
      minTimeHours = 0;
      minTimeMinutes = 0;
    }
    return (minTimeHours.toString().length < 2 ? '0' : '') + minTimeHours.toString() + ':' +
      (minTimeMinutes.toString().length < 2 ? '0' : '') + minTimeMinutes.toString() + ':00';
  }

  /**
   * Returns the maximum time of the last slot of the merchant's opening hours.
   * If the merchant has no opening hours, returns '24:00:00'.
   * @returns {string} The maximum time in the format 'HH:mm:ss'.
   */
  private _getCalendarMaxTime(): string {
    let maxTimeHours = 0;
    let maxTimeMinutes = 0;
    if (this.merchant.openingHours !== null) {
      for (const day of this.merchant.openingHours.days) {
        if (day.open) {
          const lastSlot = day.slots[day.slots.length - 1];
          let toMinutes, toHours, toMatch;
          toMatch = lastSlot.to.selected.match(/([0-1]?[0-9]):([0-5][0-9]) (am|pm)/);
          toHours = toMatch[3] === 'am' ? parseInt(toMatch[1]) : parseInt(toMatch[1]) + 12;
          toMinutes = parseInt(toMatch[2]);
          if (toHours > maxTimeHours) {
            maxTimeHours = toHours;
            maxTimeMinutes = toMinutes;
          } else if ((toHours === maxTimeHours) && (toMinutes > maxTimeHours)) {
            maxTimeMinutes = toMinutes;
          }
        }
      }
    }
    if ((maxTimeHours === 0) && (maxTimeMinutes === 0)) {
      maxTimeHours = 24;
      maxTimeMinutes = 0;
    }
    return (maxTimeHours.toString().length < 2 ? '0' : '') + maxTimeHours.toString() + ':' +
      (maxTimeMinutes.toString().length < 2 ? '0' : '') + maxTimeMinutes.toString() + ':00';
  }

  /**
   * Returns an array of business hours for the merchant based on their opening hours.
   * @returns An array of business hours.
   */
  private _getMerchantBusinessHours() {
    const businessHours = [];
    let dayIdx = 1;
    if (this.merchant.openingHours !== null) {
      for (const day of this.merchant.openingHours.days) {
        if (day.open === false) {
          businessHours.push({
            daysOfWeek: [dayIdx],
            startTime: '00:00',
            endTime: '00:00'
          });
        } else {
          for (const slot of day.slots) {
            let from_match, from_hours, to_match, to_hours;
            from_match = slot.from.selected.match(/([0-1]?[0-9]):([0-5][0-9]) (am|pm)/);
            from_hours = from_match[3] == 'am' ? parseInt(from_match[1]) : parseInt(from_match[1]) + 12;
            to_match = slot.to.selected.match(/([0-1]?[0-9]):([0-5][0-9]) (am|pm)/);
            to_hours = to_match[3] == 'am' ? parseInt(to_match[1]) : parseInt(to_match[1]) + 12;
            businessHours.push({
              daysOfWeek: [dayIdx],
              startTime: from_hours.toString() + ':' + from_match[2],
              endTime: to_hours.toString() + ':' + to_match[2]
            });
          }
        }
        dayIdx += 1;
      }
    }
    return businessHours;
  }

  /**
   * Formats the events array to match the expected format for the calendar component.
   * @param events - The events array to format.
   * @param employeeId - The ID of the employee associated with the events.
   * @param googleCalendarId - The ID of the Google Calendar associated with the events.
   * @returns The formatted events array.
   */
  private _formatEvents(events, employeeId: string, googleCalendarId: string) {
    events = events.items.map((event: any) => {
      const formattedEvent: any = {
        id: event.id,
        title: event.summary,
        source: googleCalendarId,
        resourceId: employeeId,
        description: event.description,
        created: moment(event.created),
        attendees: event.attendees,
        extendedProps: {
          googleCalendarId
        }
      };
      if (event.start.dateTime) {
        formattedEvent.start = moment(event.start.dateTime).tz(this.settings.timezone).toDate();
      } else if (event.start.date) {
        formattedEvent.start = moment(event.start.date).startOf('day').tz(this.settings.timezone).toDate();
      }
      if (event.end.dateTime) {
        formattedEvent.end = moment(event.end.dateTime).tz(this.settings.timezone).toDate();
      } else if (event.end.date) {
        formattedEvent.end = moment(event.end.date).endOf('day').tz(this.settings.timezone).toDate();
      }
      if (event.summary === '@DAYOFF') {
        formattedEvent.title = 'Day off';
        formattedEvent.classNames = ['day-off'];
      }
      if (event.extendedProperties && event.extendedProperties.shared) {
        if (event.extendedProperties.shared.orderId) {
          formattedEvent.extendedProps.orderId = event.extendedProperties.shared.orderId;
        }
        if (event.extendedProperties.shared.orderStatus) {
          if (event.extendedProperties.shared.orderStatus === 'paid') {
            formattedEvent.classNames = ['paid'];
          } else {
            formattedEvent.className = ['not-paid'];
          }
        } else {
          formattedEvent.className = ['unknown'];
        }
      }
      return formattedEvent;
    });
    return events;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Updates the calendar sources based on the available employees and selected employee.
   * If the component is disabled, it returns early.
   * If the fullCalendar instance exists, it removes event sources that are not in the available employees list
   * and adds event sources that are in the available employees list but not in the fullCalendar instance.
   * If the selected employee is 'any', it sets the calendar resources to all available employees except 'any'.
   * Otherwise, it sets the calendar resources to the selected employee.
   * Finally, it rerenders the events in the fullCalendar instance.
   */
  updateCalendarSources() {
    if (!this._componentEnabled) {
      return;
    }
    if (this.selectedEmployee?._id === 'any') {
      this.selectedEventSources = this.allEventSources;
    } else {
      this.selectedEventSources = this.allEventSources.filter(eventSource => eventSource.id === this.selectedEmployee._id);
    }
    this.calendarOptions.eventSources = this.selectedEventSources;
    if (this.fullCalendar) {
      this.fullCalendar.getApi().render();
    }
  }

  /**
   * Returns the duration of a calendar time slot as a string.
   * If the component is disabled, returns undefined.
   * If the component is enabled but no time slot granularity is set, returns '00:15'.
   * If the component is enabled and a time slot granularity is set, returns the duration as a string in the format 'HH:mm'.
   * @returns The duration of a calendar time slot as a string, or undefined if the component is disabled.
   */
  getCalendarSlotDuration(): string {
    if (!this._componentEnabled) {
      return;
    }
    if (this.settings && this.settings.calendarTimeSlotGranularity) {
      const hours = Math.floor(this.settings.calendarTimeSlotGranularity / 60);
      const minutes = this.settings.calendarTimeSlotGranularity % 60;
      return (hours < 10 ? '0' : '') + `${hours}:` + (minutes < 10 ? '0' : '') + `${minutes}`;
    }
    return '00:15';
  }

  /**
   * Sets the loading state of the calendar.
   * @param isLoading Whether the calendar is currently loading or not.
   */
  loadingCalendar(isLoading: boolean) {
    this.isLoadingCalendar = isLoading;
  }

  /**
   * Selects a service and updates the component state accordingly.
   * @param service - The service to select.
   */
  selectService(service: Product) {
    if (!this._componentEnabled) {
      return;
    }
    this.selectedService = service;
    // Compute the set of attributes for which a variant exists
    this.attributes = [];
    if (this.selectedService) {
      const attributeNames = Array.from(new Set(...this.selectedService.variantItems.map(variantItem => variantItem.attributes.map(attribute => attribute.name))));
      for (let attributeName of attributeNames) {
        const options = Array.from(new Set(this.selectedService.variantItems.map(variantItem => variantItem.attributes.find(attribute => attribute.name === attributeName).option)));
        this.attributes.push({
          type: 'whatever',
          name: attributeName,
          description: '',
          options: options,
          displayedAsFilter: false
        });
      }
    }
    this.selectedVariants = {};
    if (service.hasVariants) {
      // Select the first valid variantItem
      const validVariantItem = service.variantItems.find(variantItem => variantItem.valid);
      for (let attributeIdx = 0; attributeIdx < this.attributes.length; attributeIdx++) {
        this.selectedVariants[attributeIdx] = validVariantItem.attributes[attributeIdx].option;
      }
      this.currentStep = 'selectVariant';
    } else {
      this.currentStep = 'selectEmployee';
    }
    this.selectVariant();
    // Get the list of employees who can provide the selected service
    this.availableEmployees = this.employees.filter(employee => employee.serviceProvided.includes(this.selectedService._id.toString()));
    const anyEmployee: Employee = {
      _id: 'any',
      name: 'Any employee',
      email: '',
      description: "",
      role: "",
      phone: "",
      merchantId: '',
      photos: [],
      active: true,
      workSchedule: null,
      serviceProvided: null,
      createdAt: new Date(),
      updatedAt: new Date(),
      updatedBy: '',
      daysOff: []
    };
    if (this.availableEmployees.length > 1) {
      this.availableEmployees.unshift(anyEmployee);
    }
    this.selectedEmployee = this.availableEmployees[0];
    this.selectEmployee();
  }

  /**
   * Selects the variant item based on the selected service and variants.
   * If the component is disabled or no service is selected, the function returns early.
   * If the selected service has variants, the function finds the variant item that matches the selected variants.
   * If no matching variant item is found, the function selects the first variant item.
   * Finally, the function refreshes the available time slots based on the selected date.
   */
  selectVariant() {
    if (!this._componentEnabled) {
      return;
    }
    if (!this.selectedService) {
      return;
    }
    if (this.selectedService.hasVariants) {
      this.selectedVariantItem = this.selectedService.variantItems.find(variantItem => variantItem.attributes.map(attribute => attribute.option).every(option => Object.values(this.selectedVariants).includes(option)));
    } else {
      this.selectedVariantItem = this.selectedService.variantItems[0];
    }
    this.refreshAvailableTimeSlots(this.selectedDate);
  }

  /**
   * Selects an employee and updates the view accordingly.
   * If the component is disabled, the function returns early.
   * If the selected employee is 'any', the 'showNewEvent' property is set to true.
   * The available time slots are refreshed for the selected date.
   * The calendar sources are updated.
   * The calendar is re-rendered.
   * The selected time slot is set to null.
   */
  selectEmployee() {
    if (!this._componentEnabled) {
      return;
    }
    this.showNewEvent = this.selectedEmployee._id === 'any';
    this.refreshAvailableTimeSlots(this.selectedDate, true);
    this.updateCalendarSources();
    this._renderCalendar();
    this.selectedTimeSlot = null;
  }

  /**
   * Saves the order if all required fields are selected and opens a confirmation dialog.
   * If the selected employee is "any", randomly selects an available employee for the selected time slot.
   */
  saveOrder() {
    if (!this._componentEnabled) {
      return;
    }
    if (!this.selectedEmployee || !this.selectedDate || !this.selectedTimeSlot) {
      return;
    }

    // If any employee chosen, then pick one.
    if (this.selectedEmployee._id === 'any') {
      const timeSlot = this.selectedDay.availability.find(avl => avl.time === this.selectedTimeSlot)
      if (timeSlot && timeSlot.employees && timeSlot.employees.length > 0) {
        const availableEmployeeIds = timeSlot.employees.map(employee => employee.id);
        const randomIdx: number = Math.floor(Math.random() * availableEmployeeIds.length);
        this.selectedEmployee = this.employees.find(employee => employee._id === availableEmployeeIds[randomIdx]);
      } else {
        return;
      }
    }

    // Open the confirmation dialog
    const confirmation = this._fuseConfirmationService.open({
      title: this._translocoService.translate(
        `${this._translocoRead}.confirmation.title`
      ),
      message: this._translocoService.translate(
        `${this._translocoRead}.confirmation.message`,
        {
          service: this.selectedService.name,
          date: `${this._datePipe.transform(moment(this.selectedTimeSlot).tz(this.settings.timezone).toDate(), 'MMMM d, y')}`,
          time: this._formatTimeSlot(moment(this.selectedTimeSlot).tz(this.settings.timezone)),
          employee: this.selectedEmployee.name
        }
      ),
      icon: {
        show: true,
        name: 'heroicons_outline:question-mark-circle',
        color: 'primary'
      },
      actions: {
        confirm: {
          label: this._translocoService.translate(
            `${this._translocoRead}.confirmation.btn-confirm`
          ),
          color: 'primary'
        }
      }
    });

    confirmation.afterClosed().subscribe(result => {
      if (result === 'confirmed') {
        this.savingOrder = true;
        let eventData = null;
        eventData = {
          serviceId: this.selectedService._id.toString(),
          variantId: this.selectedVariantItem._id.toString(),
          customerName: this.customerName,
          customerEmail: this.customerEmail,
          startDate: moment(this.selectedTimeSlot).tz(this.settings.timezone),
          employeeId: this.selectedEmployee._id,
          availableEmployeeIds: []
        };

        // create the Qart Order
        const qartOrder = new QartOrder();
        // Display the loading dialog
        //const dialogLoading = this.dialog.open(DialogLoading, {width: '350px'});

        this._productService.getProductById(eventData.serviceId)
          .subscribe((selectedService: Product) => {
            const productOrder = new ProductOrder(selectedService);
            productOrder.selectedVariant = selectedService.variantItems.find(variantItem => variantItem._id == eventData.variantId);
            productOrder.selectedEmployee = eventData.employeeId;
            productOrder.availableEmployees = eventData.availableEmployeeIds;
            productOrder.selectedServiceTimeSlot = eventData.startDate;
            productOrder.status = "confirmed";
            qartOrder.productOrders.push(productOrder);
            this._orderService.saveOrderOnBehalf(qartOrder, eventData.customerEmail, eventData.customerName)
              .subscribe((addedOrder: Order) => {
                this.savingOrder = false;
                this.closeDrawer()
              });
          })
      }
    });
  }

  /**
   * Closes the booking drawer and sets the current step to 'selectService'.
   */
  closeDrawer() {
    this.currentStep = 'selectService';
    this._fuseDrawerService.getComponent('bookingDrawer').close();
  }

  /**
   * Returns the availability of an employee for a given day.
   * @param day - The day for which to retrieve the availability.
   * @param employeeId - The ID of the employee for which to retrieve the availability.
   * @returns An array of time slots representing the availability of the employee for the given day.
   */
  getAvailabilityOfEmployee(day: any, employeeId: string) {
    if (!this._componentEnabled) {
      return;
    }
    if (!day || !employeeId) {
      return [];
    }
    if ((employeeId === undefined) || (employeeId === 'any') || (employeeId === '')) {
      return day.availability;
    }
    const timeSlots = day.availability.filter(elem => elem.employees.map(employee => employee.id).includes(employeeId));
    return timeSlots;
  }

  /**
   * Determines whether a given date should be filtered out of the available dates for booking.
   * @param inputDate The date to check.
   * @returns True if the date should be filtered out, false otherwise.
   */
  filterDate = (inputDate: moment.Moment): boolean => {
    if (!this._componentEnabled) {
      return;
    }
    if (!inputDate) {
      return false;
    }
    const date: Date = inputDate.toDate();
    date.setHours(0, 0, 0, 0);
    // Check if there are available hours in the input date
    if (this.availableTimes !== undefined && this.selectedEmployee) {
      const day = this.availableTimes.find(avlTime => {
        const avlTimeDate = new Date(avlTime.date);
        avlTimeDate.setHours(0, 0, 0, 0);
        return avlTimeDate.getTime() === date.getTime();
      });
      if (day !== undefined) {
        const timeSlots = this.getAvailabilityOfEmployee(day, this.selectedEmployee._id);
        return timeSlots.length > 0;
      }
    }
    if (date < this._firstFetchedDate || date > this._lastFetchedDate) {
      return true;
    }
    return false;
  }

  /**
   * Filters available times based on the selected service's availability.
   * @returns void
   */
  filterAvailabilityWithProductAvailability() {
    if (!this._componentEnabled) {
      return;
    }
    if (this.selectedService.availability.type === 'specific') {
      const productAvailability = this.selectedService.availability.times
        .map(time => moment(time).tz(this.settings.timezone).toDate());
      this.availableTimes = this.availableTimes
        .filter(avlTime => productAvailability.some(day => moment(avlTime.date).isSame(day)));
    } else if (this.selectedService.availability.type === 'repeat') {
      if (this.selectedService.availability.period === 'yearly') {
        this.availableTimes = this.availableTimes
          .filter(avlTime => this.selectedService.availability.repeat.some(month => moment(avlTime.date).month() === month.id));
      } else if (this.selectedService.availability.period === 'monthly') {
        this.availableTimes = this.availableTimes
          .filter(avlTime => this.selectedService.availability.repeat.some(day => moment(avlTime.date).date() === day.id));
      } else if (this.selectedService.availability.period === 'weekly') {
        this.availableTimes = this.availableTimes
          .filter(avlTime => this.selectedService.availability.repeat.some(day => moment(avlTime.date).day() === day.id));
      } else if (this.selectedService.availability.period === 'daily') {
        this.availableTimes.forEach(avlTime =>
          avlTime.availability = avlTime.availability.filter(avl =>
            this.selectedService.availability.repeat.some(hour => moment(avl.time).hour() === hour.id)
            && this.selectedService.availability.repeat.some(hour => moment(avl.time).add(this.selectedVariantItem.duration - 1, 'minutes').hour() === hour.id)
            // I removed 1 from the duration so that a service of 30 minutes can be schedule at 5:30 even if the end time is 6 (which is not available let say)
          )
        );
      }
    }
  }

  /**
   * Returns the converted time in either hours or minutes based on the `component` parameter.
   * @param timeSlot - The time slot to convert in the format of `hh:mm am/pm`.
   * @param component - The component to return, either `'h'` for hours or `'m'` for minutes.
   * @returns The converted time in either hours or minutes.
   */
  getConvertedTime(timeSlot: string, component: string): number {
    if (!this._componentEnabled) {
      return;
    }
    let time = timeSlot.match(/^(\d|1[0-2])(:)([0-5]\d) (am|pm)$/);
    if (time && time.length == 5) {
      if (component === 'm') {
        return parseInt(time[3]);
      } else if (component === 'h') {
        return time[4] === "pm" ? parseInt(time[1]) + 12 : parseInt(time[1]);
      }
    }
    return null;
  }

  /**
   * Filters the available times based on the merchant's opening hours.
   * @returns void
   */
  filterAvailabilityWithMerchantOpeningHour() {
    if (!this._componentEnabled) {
      return;
    }
    const DayToIndexMapping = {
      'Sunday': 0,
      'Monday': 1,
      'Tuesday': 2,
      'Wednesday': 3,
      'Thursday': 4,
      'Friday': 5,
      'Saturday': 6
    };
    // Get all the slots from each day of the week
    let openDays = this.merchant.openingHours.days.map(day => ({
      id: DayToIndexMapping[day.name],
      open: day.open,
      slots: day.slots.map(slot => ({
        from: {
          hours: this.getConvertedTime(slot.from.selected, 'h'),
          minutes: this.getConvertedTime(slot.from.selected, 'm'),
        },
        to: {
          hours: this.getConvertedTime(slot.to.selected, 'h'),
          minutes: this.getConvertedTime(slot.to.selected, 'm'),
        },
      }))
    }));
    // Filter out the days the shop is closed
    this.availableTimes = this.availableTimes.filter(avlTime => openDays.find(openDay => openDay.id === moment(avlTime.date).day()).open);
    // Filter out the hours at which the shop is closed
    this.availableTimes.forEach(avlTime => {
      avlTime.availability = avlTime.availability.filter(avl => {
        const dayOfWeek: number = moment(avl.time).day();
        const openDay = openDays.find(openDay => openDay.id === dayOfWeek);
        if (!openDay) {
          return false;
        }
        for (let slot of openDay.slots) {
          let start: moment.Moment = moment(avl.time);
          start.set({
            hour: slot.from.hours,
            minute: slot.from.minutes,
            second: 0,
            millisecond: 0
          });
          let end: moment.Moment = moment(avl.time);
          end.set({
            hour: slot.to.hours,
            minute: slot.to.minutes,
            second: 0,
            millisecond: 0
          });
          // make sure that the start date and the end date are within the open time-slot
          if (start.isSameOrBefore(avl.time) && moment(avl.time).add(this.selectedVariantItem.duration, 'minutes').isSameOrBefore(end)) {
            return true;
          }
        }
        return false;
      })

    });
  }

  /**
   * Refreshes the available time slots for the given month and updates the displayed date.
   * @param currentDate The current date to refresh the available time slots for.
   * @param force If true, forces the refresh even if the current month is already displayed.
   * @returns Null if an error occurs during the refresh, otherwise nothing.
   */
  async refreshAvailableTimeSlots(currentDate: Date, force: boolean = false) {
    if (!this._componentEnabled) {
      return;
    }
    if (!force && currentDate.getMonth() === this.displayedDate.getMonth()) {
      // Update the selected day
      currentDate = moment(currentDate).tz(this.settings.timezone).toDate();
      this.displayedDate = new Date(currentDate);
      currentDate.setHours(0, 0, 0, 0);
      this.selectedDay = this.availableTimes.find((avlTime) => {
        const avlTimeDate = new Date(avlTime.date);
        avlTimeDate.setHours(0, 0, 0, 0);
        return avlTimeDate.getTime() === currentDate.getTime();
      });
      if (!this.selectedDay) {
        this.selectedDayHasAvailableTime = false;
      } else {
        const employeeId: string = this.selectedEmployee._id;
        const selectedDayTime: number = this.selectedDay.date.getTime();
        this.selectedDayHasAvailableTime = this.employeeAvailability[employeeId][selectedDayTime].length > 0;
      }
    } else {
      // Refreshing the available time slots in the month.
      this.loadingDayAvailability = true;
      const startDate = moment(currentDate)
        .startOf('month')
        .tz(this.settings.timezone)
        .toDate();
      this._firstFetchedDate = new Date(startDate);
      const currentDateCpy = new Date(currentDate).setMonth(
        currentDate.getMonth() + numberOfMonthsAhead
      );
      const endDate = moment(currentDateCpy)
        .endOf('month')
        .tz(this.settings.timezone)
        .toDate();
      this._lastFetchedDate = new Date(endDate);
      currentDate = moment(currentDate).tz(this.settings.timezone).toDate();
      try {
        // The availability is given in the merchant timezone
        const availability = await firstValueFrom(this._calendarService.getAvailableTimeSlots(
          this.availableEmployees.filter(employee => employee._id !== 'any').map(employee => employee._id),
          startDate,
          endDate,
          this.selectedVariantItem.duration));
        // Convert to merchant timezone and then assumes user timezone (invert of above)
        availability.forEach(dayAvl => {
          dayAvl.date = moment(dayAvl.date)
            .tz(this.settings.timezone)
            .add(1, 'h') // Added one hour to compensate for the daylight saving time
            .toDate();
          dayAvl.availability.forEach(timeAvl => {
            timeAvl.time = moment(timeAvl.time)
              .tz(this.settings.timezone)
              .toDate();
          });
        });
        this.availableTimes = availability;
        this.filterAvailabilityWithProductAvailability();
        if (!this.merchant.onlineOnly) {
          this.filterAvailabilityWithMerchantOpeningHour();
        }
        // Take into account the "calendarBlockedDelay" set up in the booking settings
        this.minCalendarDate = moment(new Date())
          .tz(this.settings.timezone)
          .add(this.settings.calendarBlockedDelay ?? 0, 'h')
          .toDate();
        this.availableTimes.forEach(
          availableTime =>
          (availableTime.availability = availableTime.availability.filter(
              avl => avl.time > this.minCalendarDate
          ))
        );
      } catch (err) {
        return null;
      } finally {
        this.loadingDayAvailability = false;
        // Mark for check
        this._changeDetectorRef.markForCheck();
      }
    }
    this.displayedDate = new Date(currentDate);
    currentDate.setHours(0, 0, 0, 0);
    this.selectedDay = this.availableTimes.find(avlTime => {
      const avlTimeDate = new Date(avlTime.date);
      avlTimeDate.setHours(0, 0, 0, 0);
      return avlTimeDate.getTime() === currentDate.getTime();
    });

    // Update the values in the template
    this._updateTemplateData();
    if (!this.selectedDay) {
      this.selectedDayHasAvailableTime = false;
    } else {
      const employeeId: string = this.selectedEmployee._id;
      const selectedDayTime: number = this.selectedDay.date.getTime();
      this.selectedDayHasAvailableTime = this.employeeAvailability[employeeId][selectedDayTime].length > 0;
    }
    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Formats the given moment object to a string in the format "HH:mm".
   * @param date - The moment object to format.
   * @returns A string in the format "HH:mm".
   */
  private _formatTimeSlot(date: moment.Moment): string {
    const hours: string = date.hours() < 10 ? `0${date.hours()}` : `${date.hours()}`;
    const minutes: string = date.minutes() < 10 ? `0${date.minutes()}` : `${date.minutes()}`;
    return `${hours}:${minutes}`;
  }

  /**
   * Updates the selected date and refreshes the available time slots.
   * @param newDate - The new date to be selected.
   */
  selectDate(newDate: any) {
    if (!this._componentEnabled) {
      return;
    }
    this.selectedDate = newDate.value.toDate();
    this.selectedTimeSlot = undefined;
    if (this.fullCalendar) {
      this.fullCalendar.getApi().gotoDate(this.selectedDate);
    }
    this.refreshAvailableTimeSlots(this.selectedDate);
  }

  /**
   * Displays the month view for the given `firstDateOfMonth`.
   * 
   * @param firstDateOfMonth The first date of the month to display.
   */
  viewMonth(firstDateOfMonth) {
    if (!this._componentEnabled) {
      return;
    }
    this.refreshAvailableTimeSlots(firstDateOfMonth);
  }

  /**
   * Selects the time slot for the service.
   * If the component is disabled, the function returns without doing anything.
   * @returns void
   */
  selectTimeSlot() {
    if (!this._componentEnabled) {
      return;
    }
    const timeSlot = this.selectedServiceTimeSlotObject;
    this.selectedTimeSlot = timeSlot.time;
  }

  /**
   * Checks if the given option is a valid color.
   * @param option - The color option to check.
   * @returns True if the option is a valid color, false otherwise.
   */
  isColor(option: string): boolean {
    if (!this._componentEnabled) {
      return false;
    }
    option = option.toLowerCase()
    var s = new Option().style;
    s.color = option;
    var test1 = s.color == option;
    var test2 = /^#[0-9A-Fa-f]{6}$/i.test(option);
    if (test1 == true || test2 == true) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Returns the duration component in hours or minutes.
   * @param duration - The duration in minutes.
   * @param component - The component to return ('h' for hours, 'm' for minutes).
   * @returns The duration component in hours or minutes.
   */
  getDurationComponent(duration: number, component: string): number {
    if (!this._componentEnabled) {
      return 0;
    }
    if (component === 'h') {
      return Math.floor(duration / 60);
    } else if (component === 'm') {
      return duration % 60;
    } else {
      return 0;
    }
  }

  /**
   * Returns the duration of a variant item if the option was selected.
   * @param attributeIdx - The index of the attribute to update.
   * @param option - The option to select.
   * @returns The duration of the variant item if it exists, otherwise 0.
   */
  getDurationIfOptionWasSelected(attributeIdx, option): number {
    if (!this._componentEnabled) {
      return 0;
    }
    if (!this.selectedVariantItem) {
      return 0;
    }
    const currentOptions = [];
    for (const option of this.selectedVariantItem.attributes.map(attribute => attribute.option)) { currentOptions.push(option); }
    currentOptions[attributeIdx] = option;
    const variantItem: VariantItem = this.selectedService.variantItems.find((elem, idx, obj) => JSON.stringify(elem.attributes.map(attribute => attribute.option)) === JSON.stringify(currentOptions));
    return variantItem.duration ? variantItem.duration : 0;
  }

  /**
   * Checks if there is a discount for a specific variant attribute option.
   * @param attributeIdx - The index of the attribute to check.
   * @param option - The option to check for a discount.
   * @returns True if there is a discount for the specified variant attribute option, false otherwise.
   */
  isDiscountForVariant(attributeIdx, option): boolean {
    if (!this._componentEnabled) {
      return false;
    }
    if (!this.selectedVariantItem) {
      return false;
    }
    const currentOptions = [];
    for (const option of this.selectedVariantItem.attributes.map(attribute => attribute.option)) { currentOptions.push(option); }
    currentOptions[attributeIdx] = option;
    const variantItem: VariantItem = this.selectedService.variantItems.find((elem, idx, obj) => JSON.stringify(elem.attributes.map(attribute => attribute.option)) === JSON.stringify(currentOptions));
    return variantItem.discountPrice && variantItem.discountPrice < variantItem.price;
  }

  /**
   * Returns the price of the selected variant item if the given option was selected for the attribute at the given index.
   * @param attributeIdx The index of the attribute for which the option was selected.
   * @param option The option that was selected for the attribute at the given index.
   * @returns The price of the selected variant item with the given option selected for the attribute at the given index.
   */
  getPriceIfOptionWasSelected(attributeIdx, option): number {
    if (!this._componentEnabled) {
      return 0;
    }
    if (!this.selectedVariantItem) {
      return 0;
    }
    const currentOptions = [];
    for (const option of this.selectedVariantItem.attributes.map(attribute => attribute.option)) { currentOptions.push(option); }
    currentOptions[attributeIdx] = option;
    const variantItem: VariantItem = this.selectedService.variantItems.find((elem, idx, obj) => JSON.stringify(elem.attributes.map(attribute => attribute.option)) === JSON.stringify(currentOptions));
    return variantItem.discountPrice ? variantItem.discountPrice : variantItem.price;
  }

  /**
   * Calculates the price difference if an option was selected.
   * @param attributeIdx - The index of the attribute.
   * @param option - The selected option.
   * @returns The price difference between the selected option and the current price.
   */
  getPriceDifferenceIfOptionWasSelected(attributeIdx, option): number {
    if (!this._componentEnabled) {
      return 0;
    }
    if (!this.selectedVariantItem) {
      return 0;
    }
    const discountPrice = this.getPriceIfOptionWasSelected(attributeIdx, option);
    const currentPrice = this.selectedVariantItem.discountPrice ? this.selectedVariantItem.discountPrice : this.selectedVariantItem.price;
    return discountPrice - currentPrice;
  }

  /**
   * Determines if an option is disabled based on the current selected variant item and its attributes.
   * @param attributeIdx - The index of the attribute to check.
   * @param option - The option to check.
   * @returns True if the option is disabled, false otherwise.
   */
  isOptionDisabled(attributeIdx, option): boolean {
    if (!this._componentEnabled) {
      return false;
    }
    if (!this.selectedVariantItem) {
      return false;
    }
    const currentOptions = [];
    for (const option of this.selectedVariantItem.attributes.map(attribute => attribute.option)) { currentOptions.push(option); }
    currentOptions[attributeIdx] = option;
    return <boolean>!this.selectedService.variantItems.find((elem, idx, obj) => JSON.stringify(elem.attributes.map(attribute => attribute.option)) === JSON.stringify(currentOptions)).valid;
  }

}
