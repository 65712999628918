import { HttpEvent, HttpHandlerFn, HttpHeaders, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@env/environment';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { TranslocoService } from '@jsverse/transloco';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { catchError, Observable, throwError } from 'rxjs';


/**
 * Interceptor that adds the Authorization header to HTTP requests if the access token is not expired.
 * If the access token is expired, the server will return a "401 Unauthorized" response which will be caught
 * by the response interceptor to log the user out from the app.
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {

    const authService = inject(AuthService);
    const fuseConfirmationService: FuseConfirmationService = inject(FuseConfirmationService);
    const translocoService: TranslocoService = inject(TranslocoService);
    const currentLanguage: string = translocoService.getActiveLang();
    const translocoRead: string = "interceptor";

    // Clone the request object
    let newReq = req.clone();
    let headers: HttpHeaders = newReq.headers;
    
    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if ( authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken) ) {
        headers = headers.set('Authorization', 'Bearer ' + authService.accessToken);
        newReq = newReq.clone({ headers });
    }

    headers = headers.set('X-Qart-origin-client', 'admin');
    headers = headers.set('X-Qart-origin-env', environment.name);
    headers = headers.set('X-Qart-lang', currentLanguage);
    newReq = newReq.clone({ headers });
    
    // Response
    return next(newReq).pipe(
        catchError((error) =>
        {
            // Catch "401 Unauthorized" responses
            if (error.status === 401) {
                console.log(error.error.message);
                // Sign out
                authService.signOut(true);

                // Reload the app
                location.reload();
            }
            else if (error.status === 424) {
                const dialogAlert = fuseConfirmationService.open({
                    title: translocoService.translate(`${translocoRead}.error-title`),
                    message: error.error?.message || error.message,
                    icon: {
                        show: true,
                        name: 'heroicons_outline:exclamation-triangle',
                        color: 'error'
                    },
                    actions: {
                        confirm: {
                            show: true,
                            label: translocoService.translate(`${translocoRead}.btn-ok`),
                            color: 'warn'
                        },
                        cancel: {
                            show: false
                        }
                    },
                    dismissible: false
                });
                dialogAlert.afterClosed().subscribe();
            }
            console.log("error", error);
            return throwError(() => error);
        }),
    );
};
