import { Injectable } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { getBrowserLang, LangDefinition, TranslocoService } from "@jsverse/transloco";
import { TranslocoLocaleService } from "@jsverse/transloco-locale";
import { CookieService } from "ngx-cookie-service";


/**
 * Resolves the language to be used in the application based on various sources such as query params, cookies, local storage, hostname and browser settings.
 */
@Injectable({
  providedIn: 'root'
})
export class LanguageResolver implements Resolve<string> {
  /**
   * The available languages in the application.
   */
  private availableLangs: LangDefinition[];

  /**
   * Mapping of hostnames to language codes.
   */
  private _hostToLangMapping: any;

  /**
   * List of language codes and their corresponding locales.
   */
  private _localeList: { code: string; locale: string }[];

  /**
   * Constructs a new instance of the LanguageResolver class.
   * @param _translocoService The TranslocoService instance to use for language translation.
   * @param _cookieService The CookieService instance to use for cookie management.
   * @param _translocoLocaleService The TranslocoLocaleService instance to use for locale management.
   * @param _dateAdapter The DateAdapter instance to use for date formatting.
   */
  constructor(
    private _translocoService: TranslocoService,
    private _cookieService: CookieService,
    private _translocoLocaleService: TranslocoLocaleService,
    private _dateAdapter: DateAdapter<any>
  ) {
    // Get the available languages from transloco
    this.availableLangs =
      this._translocoService.getAvailableLangs() as LangDefinition[];

    this._hostToLangMapping = {
      my: "en",
      mon: "fr",
      mein: "de",
    };
    this._localeList = [
      { code: "en", locale: "en-US" },
      { code: "de", locale: "de-DE" },
      { code: "fr", locale: "fr-FR" },
    ];
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sets the active language and updates the locale accordingly.
   * @param lang - The language to set as active.
   * @returns The language that was set as active.
   */
  private _setActiveLang(lang: string): string {
    // Verify that the language is available
    if (!this.availableLangs.map((l) => l.id).includes(lang)) {
      lang = "en";
    }

    // Verify that the language is not the same as the current one
    const activeLanguage: string = this._translocoService.getActiveLang();
    if (activeLanguage !== lang) {
      // Set the active lang
      this._translocoService.setActiveLang(lang);
    }

    // Update the locale
    const userLocale: string = this._localeList.find(
      (o) => o.code === lang
    ).locale;
    this._translocoLocaleService.setLocale(userLocale);
    this._dateAdapter.setLocale(userLocale);

    // Save the user preference
    localStorage.setItem("lang", lang);

    // Save it in a cookie as well so that it can be re-used across subdomains)
    this._cookieService.set('lang', lang, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      path: '/',
      domain: 'qart.shop',
      secure: true,
      sameSite: 'Lax'
    });

    // Push the language to GTM
    if (window["dataLayer"]) {
      window["dataLayer"].push({
        language: lang
      });
    }
    return lang;
  }

  /**
   * Returns the language from the query parameters of the given activated route snapshot.
   * If the language is found, it sets it as the active language.
   * @param route The activated route snapshot.
   * @returns The language from the query parameters.
   */
  private _getLanguageFromQueryParams(route: ActivatedRouteSnapshot): string {
    let language: string = route.queryParams["lang"];
    if (language) {
      language = this._setActiveLang(language);
    }
    return language;
  }

  /**
   * Retrieves the language from the cookie service.
   * If the language is found, it sets it as the active language.
   * @param route - The activated route snapshot.
   * @returns The language retrieved from the cookie service.
   */
  private _getLanguageFromCookie(route: ActivatedRouteSnapshot): string {
    let language: string = this._cookieService.get("lang");
    if (language) {
      language = this._setActiveLang(language);
    }
    return language;
  }

  /**
   * Retrieves the language from local storage and sets it as the active language.
   * @param route - The activated route snapshot.
   * @returns The language retrieved from local storage.
   */
  private _getLanguageFromLocalStorage(route: ActivatedRouteSnapshot): string {
    let language: string = localStorage.getItem("lang");
    if (language) {
      language = this._setActiveLang(language);
    }
    return language;
  }

  /**
   * Returns the language based on the hostname of the current location.
   * @param route - The activated route snapshot.
   * @returns The language code as a string.
   */
  private _getLanguageFromHostname(route: ActivatedRouteSnapshot): string {
    let language: string = null;
    const hostname: string = location.hostname;
    if (hostname) {
      if (hostname === "localhost") {
        // Set toEnglish on localhost
        language = this._setActiveLang("fr");
      } else {
        let hostRef: string = hostname;
        if (hostRef.endsWith(".qart.shop")) {
          hostRef = hostRef.replace(/(.qart.shop$)/, "");
        }
        if (hostRef.startsWith("dev.")) {
          hostRef = hostRef.replace(/(^dev.)/, "");
        } else if (hostRef.startsWith("test.")) {
          hostRef = hostRef.replace(/(^test.)/, "");
        }
        for (const [key, value] of Object.entries(this._hostToLangMapping)) {
          if (hostRef === key) {
            language = this._setActiveLang(value.toString());
          }
        }
      }
    }
    return language;
  }

  /**
   * Returns the language code based on the user's browser language.
   * If the language is found, it sets it as the active language.
   * @param route - The activated route snapshot.
   * @returns The language code as a string.
   */
  private _getLanguageFromBrowser(route: ActivatedRouteSnapshot): string {
    let language: string = getBrowserLang();
    if (language) {
      language = this._setActiveLang(language);
    }
    return language;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolves the language to be used in the application.
   * Tries to get the language from various sources in the following order:
   * 1. Query params
   * 2. Cookies
   * 3. Local storage
   * 4. Hostname
   * 5. Browser
   * If none of the above sources provide a language, defaults to English.
   * @param route The activated route snapshot.
   * @returns The language to be used in the application.
   */
  resolve(route: ActivatedRouteSnapshot): string {
    let language: string = "en";

    // Try to get the language from the query params
    language = this._getLanguageFromQueryParams(route);
    if (language) {
      return language;
    }

    // Try to get the language from the cookies
    language = this._getLanguageFromCookie(route);
    if (language) {
      return language;
    }

    // Try to get the language from the local storage
    language = this._getLanguageFromLocalStorage(route);
    if (language) {
      return language;
    }

    // Try to get the language from the hostname
    language = this._getLanguageFromHostname(route);
    if (language) {
      return language;
    }

    // Try to get the language from the browser
    language = this._getLanguageFromBrowser(route);
    if (language) {
      return language;
    }

    // Set to English as default
    this._setActiveLang("en");
    return "en";
  }

}
