<!-- Floating Button -->
@if (false) {
  <div
    class="fixed flex items-center justify-center right-0 w-10 h-10 shadow-lg rounded-l-lg z-90 cursor-pointer bg-primary-600 bg-opacity-90 print:hidden"
    style="top: 12px"
    (click)="bookingDrawer.toggle()">
    <mat-icon class="icon-size-5 text-white" [svgIcon]="'heroicons_solid:calendar-days'"></mat-icon>
  </div>
}

<!-- Button toggle -->
@if (tpl.displayComponent) {
  <button
    mat-icon-button
    (click)="bookingDrawer.toggle()"
    #messagesOrigin>
    <mat-icon [svgIcon]="'heroicons_outline:calendar-days'"></mat-icon>
  </button>
}

<fuse-drawer
  class="w-screen min-w-screen md:w-200 md:min-w-200 z-999"
  fixed
  [mode]="'over'"
  [name]="'bookingDrawer'"
  [position]="'right'"
  #bookingDrawer>

  <div class="flex flex-col w-full overflow-auto bg-card" *transloco="let t; read: 'pages.admin.book'">
    <div class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary">
      <mat-icon class="icon-size-7 text-current" [svgIcon]="'heroicons_solid:calendar-days'"></mat-icon>
      <div class="ml-3 text-2xl font-semibold tracking-tight line-clamp-3">
        {{ selectedService ? selectedService.name : t('title') }}
      </div>
      <button class="ml-auto" mat-icon-button (click)="closeDrawer()">
        <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
      </button>
    </div>

    <div class="flex flex-col p-6">

      <!-- Step: Choose the service -->
      @if (currentStep === 'selectService') {
        <!-- Header -->
        <div class="flex items-center mt-4">
          <h2 class="flex leading-6 tracking-tight text-2xl font-semibold">
            {{ t('step-select-service') }}
          </h2>
        </div>
        @if (!loadingPage) {
          @if (services?.length > 0) {
            <mat-list>
              @for (service of services; track service._id) {
                <mat-list-item
                  class="cursor-pointer mt-8"
                  (click)="selectService(service)">
                  @if (tpl.hasPhoto[service._id]) {
                    <img matListAvatar [src]="tpl.firstPhotoUrl[service._id]">
                  }
                  @else {
                    <img matListAvatar class="dark:invert" src="./assets/missing-picture.png">
                  }
                  <p matLine>
                    {{ service.name }}
                  </p>
                </mat-list-item>
              }
            </mat-list>
          } @else {
            <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
              <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                <mat-icon
                  class="text-primary-500-700"
                [svgIcon]="'heroicons_outline:no-symbol'"></mat-icon>
              </div>
              <div class="mt-5 text-2xl font-semibold tracking-tight">{{ t('no-services-title') }}</div>
              <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">{{ t('no-services-subtitle') }}</div>
            </div>
          }
        } @else {
          <ng-template [ngTemplateOutlet]="loadingPageTpl"></ng-template>
        }
      }

      <!-- Step: Choose the variant -->
      @if (currentStep === 'selectVariant' && selectedService?.hasVariants) {
        <!-- Header -->
        <div class="flex items-center mt-4">
          <div class="flex items-center">
            <button
              class="flex -ml-2"
              mat-icon-button
              (click)="currentStep = 'selectService'">
              <mat-icon [svgIcon]="'heroicons_outline:arrow-left-circle'"></mat-icon>
            </button>
          </div>
          <h2 class="flex ml-1 sm:ml-4 leading-6 tracking-tight text-2xl font-semibold">
            {{ t('step-select-variant') }}
          </h2>
        </div>
        <div class="flex flex-col gap-6 w-full mt-8">
          @for (attribute of attributes; track $index; let attributeIdx = $index) {
            <mat-form-field class="hidden sm:block"
              >
              <mat-label>{{ t('select-variant-label', { name: attribute.name | lowercase }) }}</mat-label>
              <mat-select
                [(ngModel)]="selectedVariants[attributeIdx]"
                (selectionChange)="selectVariant()">
                @for (option of attribute.options; track option; let optionIdx = $index) {
                  @if (!isOptionDisabled(attributeIdx, option)) {
                    <mat-option [value]="option">
                      {{option}}
                      @if (isColor(option)) {
                        <div class="color" [style.backgroundColor]="option"></div>
                      }
                      @if (false && getDurationComponent(getDurationIfOptionWasSelected(attributeIdx, option), 'h') > 0) {
                        , {{ getDurationComponent(getDurationIfOptionWasSelected(attributeIdx, option), 'h') }} {{ mobile-hours-unit }}
                      }
                      @if (false && getDurationComponent(getDurationIfOptionWasSelected(attributeIdx, option), 'h') === 0 && getDurationComponent(getDurationIfOptionWasSelected(attributeIdx, option), 'm') > 0) {
                        ,
                      }
                      @if (false && getDurationComponent(getDurationIfOptionWasSelected(attributeIdx, option), 'm') > 0) {
                        {{ getDurationComponent(getDurationIfOptionWasSelected(attributeIdx, option), 'm') }} {{ mobile-minutes-unit }}
                      }
                      @if (getPriceDifferenceIfOptionWasSelected(attributeIdx, option) !== 0) {
                        &nbsp;&nbsp;&nbsp;
                        @if (getPriceDifferenceIfOptionWasSelected(attributeIdx, option) > 0) {
                          +
                        }
                        {{ getPriceDifferenceIfOptionWasSelected(attributeIdx, option) | currency: tpl.currency }}
                      }
                      @if (isDiscountForVariant(attributeIdx, option)) {
                        {{ t('discount') }}
                      }
                    </mat-option>
                  }
                }
              </mat-select>
            </mat-form-field>
          }
          @for (attribute of attributes; track $index; let attributeIdx = $index) {
            <mat-form-field class="sm:hidden"
              >
              <mat-label>{{ t('select-variant-label', {name: attribute.name | lowercase}) }}</mat-label>
              <select matNativeControl
                [(ngModel)]="selectedVariants[attributeIdx]"
                (ngModelChange)="selectVariant()">
                @for (option of attribute.options; track option; let optionIdx = $index) {
                  @if (!isOptionDisabled(attributeIdx, option)) {
                    <option [value]="option">
                      {{option}}
                      @if (isColor(option)) {
                        <div class="color" [style.backgroundColor]="option"></div>
                      }
                      @if (false && getDurationComponent(getDurationIfOptionWasSelected(attributeIdx, option), 'h') > 0) {
                        , {{ getDurationComponent(getDurationIfOptionWasSelected(attributeIdx, option), 'h') }} {{ mobile-hours-unit }}
                      }
                      @if (false && getDurationComponent(getDurationIfOptionWasSelected(attributeIdx, option), 'h') === 0 && getDurationComponent(getDurationIfOptionWasSelected(attributeIdx, option), 'm') > 0) {
                        ,
                      }
                      @if (false && getDurationComponent(getDurationIfOptionWasSelected(attributeIdx, option), 'm') > 0) {
                        {{ getDurationComponent(getDurationIfOptionWasSelected(attributeIdx, option), 'm') }} {{ mobile-minutes-unit }}
                      }
                      @if (getPriceDifferenceIfOptionWasSelected(attributeIdx, option) !== 0) {
                        &nbsp;&nbsp;&nbsp;
                        @if (getPriceDifferenceIfOptionWasSelected(attributeIdx, option) > 0) {
                          +
                        }
                        {{getPriceDifferenceIfOptionWasSelected(attributeIdx, option) | currency: tpl.currency}}
                      }
                      @if (isDiscountForVariant(attributeIdx, option)) {
                        {{ t('discount') }}
                      }
                    </option>
                  }
                }
              </select>
            </mat-form-field>
          }
          <div class="flex flex-row justify-end">
            <button mat-flat-button
              (click)="currentStep = 'selectEmployee'"
              color="primary">
              {{ t('btn-next') }}
            </button>
          </div>
        </div>
      }

      <!-- Step: Choose the employee -->
      @if (currentStep === 'selectEmployee') {
        <!-- Header -->
        <div class="flex items-center mt-4">
          <div class="flex items-center">
            <button
              class="flex -ml-2"
              mat-icon-button
              (click)="currentStep = 'selectVariant'">
              <mat-icon [svgIcon]="'heroicons_outline:arrow-left-circle'"></mat-icon>
            </button>
          </div>
          <h2 class="flex ml-1 sm:ml-4 leading-6 tracking-tight text-2xl font-semibold">
            {{ t('step-select-employee') }}
          </h2>
        </div>
        <div class="flex flex-col gap-6 w-full mt-8">
          <!-- Select employee -->
          @if (availableEmployees.length > 0) {
            <mat-form-field
              class="flex-auto">
              <mat-label>{{ t('select-employee-label') }}</mat-label>
              <mat-select
                placeholder="{{ t('select-employee-placeholder') }}"
                [(ngModel)]="selectedEmployee"
                (selectionChange)="selectEmployee()">
                @for (employee of availableEmployees; track employee) {
                  <mat-option
                    [value]="employee">
                    {{employee.name}}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
          <!-- Select date -->
          <mat-form-field class="flex-auto">
            <mat-label>
              @if (loadingDayAvailability) {
                {{ t('select-date-label-loading-availability') }}
              }
              @else {
                {{ t('select-date-label') }}
              }
            </mat-label>
            <input matInput
              [disabled]="loadingDayAvailability"
              [matDatepicker]="picker"
              (click)="picker.open()"
              [min]="minCalendarDate"
              [matDatepickerFilter]="filterDate"
              [(ngModel)]="selectedDate"
              autocomplete="off"
              placeholder="{{ t('select-date-placeholder') }}"
              (dateChange)="selectDate($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker (monthSelected)="viewMonth($event)"></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="flex-auto">
            <mat-label>
              @if (loadingDayAvailability) {
                {{ t('select-time-slot-label-loading-availability') }}
              }
              @else if (selectedDayHasAvailableTime) {
                {{ t('select-time-slot-label') }}
              }
              @else {
                {{ t('select-time-slot-label-no-time-slot') }}
              }
            </mat-label>
            <mat-select
              [placeholder]="!loadingDayAvailability && selectedDay !== undefined && selectedEmployee ? t('select-time-slot-placeholder') : t('select-time-slot-placeholder-loading')"
              [disabled]="!selectedDayHasAvailableTime" (selectionChange)="selectTimeSlot()"
              [(ngModel)]="selectedServiceTimeSlotObject">
              @for (timeSlot of getAvailabilityOfEmployee(selectedDay, selectedEmployee?._id); track timeSlot) {
                @if (timeSlot.time > minCalendarDate) {
                  <mat-option [value]="timeSlot">
                    {{ timeSlot.displayedStartTime }}
                    →
                    {{ timeSlot.displayedEndTime }}
                  </mat-option>
                }
              }
            </mat-select>
          </mat-form-field>
          <!-- Customer name -->
          <mat-form-field class="flex-auto">
            <mat-label>{{ t('customer-name-label') }}</mat-label>
            <mat-icon matPrefix svgIcon="heroicons_outline:user"></mat-icon>
            <input matInput
              [(ngModel)]="customerName"
              type="text"
              autocomplete="off">
            @if (!customerName || customerName === '') {
              <mat-error>
                {{ t('customer-name-error-required') }}
              </mat-error>
            }
          </mat-form-field>
          <!-- Customer email -->
          <mat-form-field class="flex-auto">
            <mat-label>{{ t('customer-email-label') }}</mat-label>
            <mat-icon matPrefix svgIcon="heroicons_outline:envelope"></mat-icon>
            <input matInput
              [(ngModel)]="customerEmail"
              type="email"
              autocomplete="off">
          </mat-form-field>
          <div class="flex flex-col sm:flex-row gap-4" >
            <button mat-button
              class="grow sm:basis-1/2 order-2 sm:order-1"
              (click)="showCalendar = !showCalendar">
              @if (showCalendar) {
                {{ t('btn-hide-calendar') }}
              }
              @else {
                {{ t('btn-show-calendar') }}
              }
            </button>
            <button mat-flat-button
              matStepperNext
              class="grow sm:basis-1/2 order-1 sm:order-2"
              [disabled]="savingOrder || !selectedEmployee || !selectedDate || !selectedTimeSlot || !customerName || customerName === ''"
              (click)="saveOrder()"
              color="primary">
              {{ t('btn-save-order') }}
            </button>
          </div>
          <div [ngClass]="{
            'block': showCalendar,
            'hidden': !showCalendar
          }">
            <mat-divider class="mt-4 mb-8"></mat-divider>
            <div class='app-calendar'>
              @if (calendarOptions) {
                <full-calendar
                  #fullCalendar
                  [options]="calendarOptions"
                ></full-calendar>
              }
            </div>
          </div>
        </div>
      }

    </div>

  </div>

</fuse-drawer>