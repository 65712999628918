import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { PermissionService } from '@core/permission/permission.service';
import { of, switchMap } from 'rxjs';

/**
 * PagePermissionGuard is a guard that checks if the user has the required permission to access a specific page.
 * It implements the CanActivateFn or CanActivateChildFn interface.
 *
 * @param route - The current route being activated.
 * @param state - The current router state.
 * @returns An Observable<boolean> indicating whether the user has the required permission.
 */
export const PagePermissionGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);
    const permissionService: PermissionService = inject(PermissionService);

    if (!route.data?.pagePermissionName) {
        return of(true);
    }
    return permissionService.checkPagePermissionObs(route.data?.pagePermissionName)
        .pipe(
            switchMap((hasPermission: boolean) => {
                if (!hasPermission) {
                    router.navigate(['/welcome']);
                    return of(false);
                }
                else {
                    return of(true);
                }
            })
        );
};
