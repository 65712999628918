import { Injectable } from "@angular/core";
import { EmployeeService } from "@core/employee/employee.service";
import { Employee } from "@core/employee/employee.types";
import { MerchantService } from "@core/merchant/merchant.service";
import { Merchant } from "@core/merchant/merchant.types";
import { Navigation } from "@core/navigation/navigation.types";
import { NotificationService } from "@core/notification/notification.service";
import { QartNotification } from "@core/notification/qart-notification.types";
import { OrderService } from "@core/order/order.service";
import { SettingsService } from "@core/settings/settings.service";
import { Settings } from "@core/settings/settings.types";
import { environment } from "@env/environment";
import { FuseNavigationItem } from "@fuse/components/navigation/navigation.types";
import { TranslocoService } from "@jsverse/transloco";
import { cloneDeep, isEqual } from "lodash-es";
import { combineLatest, debounceTime, distinctUntilChanged, Observable, of, ReplaySubject } from "rxjs";
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from "./navigation.data";
import { CACHE_EXPIRATION_5_MINUTES } from "@core/utils/http-options.types";
import { PermissionService } from "@core/permission/permission.service";
import { AccessControl } from "@core/permission/access-control.types";

/**
 * Service for managing the navigation items in the application.
 */
@Injectable({
  providedIn: "root",
})
export class NavigationService {

  /**
   * Array of notifications.
   */
  notifications: QartNotification[] = [];

  /**
   * Array of employees.
   */
  employees: Employee[] = [];

  /**
   * The merchant object.
   */
  merchant: Merchant;

  /**
   * The settings object.
   */
  settings: Settings;

  /**
   * Access control object.
   */
  accessControl: AccessControl;

  /**
   * ReplaySubject for the navigation.
   */
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

  /**
   * Default navigation items.
   */
  private _defaultNavigation: FuseNavigationItem[] = defaultNavigation;

  /**
   * Compact navigation items.
   */
  private _compactNavigation: FuseNavigationItem[] = compactNavigation;

  /**
   * Futuristic navigation items.
   */
  private _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;

  /**
   * Horizontal navigation items.
   */
  private _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

  /**
   * The translation key for the navigation items.
   */
  private _translocoRead: string = 'navigation';

  /**
   * The merchant object.
   */
  private _merchant: Merchant;
  
  /**
   * Navigation service that handles updating the navigation items based on changes in the application state.
   * @param _permissionService The permission service.
   * @param _merchantService The merchant service.
   * @param _orderService The order service.
   * @param _employeeService The employee service.
   * @param _settingsService The settings service.
   * @param _notificationService The notification service.
   * @param _translocoService The transloco service.
   */
  constructor(
    private _permissionService: PermissionService,
    private _merchantService: MerchantService,
    private _orderService: OrderService,
    private _employeeService: EmployeeService,
    private _settingsService: SettingsService,
    private _notificationService: NotificationService,
    private _translocoService: TranslocoService
  ) {

    combineLatest([
      this._employeeService.employees$,
      this._settingsService.settings$,
      this._permissionService.accessControl$
    ]).pipe(
        distinctUntilChanged((previous: any, current: any) => isEqual(previous, current))
      )
      .subscribe(([employees, settings, accessControl]: [Employee[], Settings, AccessControl]) => {
        this.employees = employees;
        this.settings = settings;
        this.accessControl = accessControl;
        if (this.employees && this.settings && this.accessControl) {
          this._updateNavigationItems();
        }
      });

    // Get the merchant
    this._merchantService.merchant$
      .subscribe((merchant: Merchant) => {
        const reload = this._merchant?._id.toString() !== merchant?._id.toString();
        this.merchant = merchant;
        if (reload && this.merchant) {
            this._updateOrderNotifications();
        }
      });

    // Get the notifications
    this._notificationService.notifications$
      .subscribe((notifications: QartNotification[]) => {
        const reload = this.notifications.filter(n => n.type === 'payOrder').length !== notifications.filter(n => n.type === 'payOrder').length;
        this.notifications = notifications;
        if (reload) {
          this._updateOrderNotifications();
        }
      });

    // Subscribe to language changes
    this._translocoService.langChanges$
      .pipe(debounceTime(100))
      .subscribe((activeLang) => {
        this._updateNavigationItems()
      });

    this._refreshNewOrderBadge();
    setInterval(this._refreshNewOrderBadge.bind(this), 60000);

    setTimeout(() => {
      this._updateNavigationItems();
    }, 5000);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns an observable of the current navigation state.
   * @returns An observable of the current navigation state.
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  
  /**
   * Retrieves the count of new orders from the order service and updates the navigation item's badge accordingly.
   * @returns {void}
   */
  private _refreshNewOrderBadge(): void {
    this._orderService.countOrders({
        sort: { field: 'createdAt', order: -1 },
        status: ['order_new'],
    }, {
      cache: true,
      expire: CACHE_EXPIRATION_5_MINUTES,
      concatenateResults: false,
      propagate: false,
      forcePropagate: false
    }).subscribe((newOrderCount: number) => { 
      if (newOrderCount > 0) {
        const ordersItem = this._findNavigationItem('orders');
        ordersItem.badge = {
          title: newOrderCount.toString(),
          classes: 'px-2 bg-green-300 text-black rounded-full'
        };
      } else {
        const ordersItem = this._findNavigationItem('orders');
        ordersItem.badge = null;
      }
      this._updateNavigationItems();
    });
  }


  /**
   * Translates all navigation items for the default, compact, futuristic, and horizontal navigation menus.
   * @returns {void}
   */
  private _translateAllNavigationItems(): void {
    this._translateNavigationItems(this._defaultNavigation, this._translocoRead);
    this._translateNavigationItems(this._compactNavigation, this._translocoRead);
    this._translateNavigationItems(this._futuristicNavigation, this._translocoRead);
    this._translateNavigationItems(this._horizontalNavigation, this._translocoRead);
  }

  /**
   * Updates the navigation items based on the current state of the application.
   * If the merchant, settings, or employees are not available, the navigation items will not be updated.
   * The agenda item will be hidden if there are no employees.
   * The help center item will be hidden if the environment is production.
   * The stripe, Google Merchant Center, and Shippo items will be hidden if they are not connected.
   * The WooCommerce item will be hidden if it is not connected, and its link will be updated if it is connected.
   * The external links item will be hidden if all of its children are hidden.
   * Finally, all navigation items will be rebuilt and translated, and the updated navigation will be emitted.
   */
  private _updateNavigationItems(): void {
    if (!this.merchant || !this.settings || !this.employees || !this.accessControl) {
        return;
    }

    // Hide the Qart items if the user does not have the required permissions
    const qartItem: FuseNavigationItem = this._defaultNavigation.find((item) => item.id === 'qart');
    qartItem.children.forEach((item) => item.hidden = (item) => !this._permissionService.checkPagePermissionSync(item.id, this.accessControl));

    // Hide the agenda
    const agendaItem = this._findNavigationItem('agenda');
    if (agendaItem) {
      agendaItem.hidden = (item) => !this._permissionService.checkPagePermissionSync('agenda', this.accessControl) || this.employees?.length === 0;
    }
    
    // Hide the help center
    const helpCenterItem = this._findNavigationItem('help-center');
    if (helpCenterItem) {
      helpCenterItem.hidden = (item) => !this._permissionService.checkPagePermissionSync('help-center', this.accessControl) || environment.name === 'production';
    }

    // Hide the merchant's opening hours
    const openingHoursItem = this._findNavigationItem('merchant-opening-hours');
    if (openingHoursItem) {
      openingHoursItem.hidden = (item) => this.merchant.onlineOnly;
    }
    
    // Hide the external links
    const stripeItem = this._findNavigationItem('stripe');
    if (stripeItem) {
      stripeItem.hidden = (item) => this.settings?.stripeConnected === false;
    }
    const googleMerchantCenterItem = this._findNavigationItem('google-merchant');
    if (googleMerchantCenterItem) {
      googleMerchantCenterItem.hidden = (item) => this.settings?.googleMerchantCenterConnected === false;
    }
    const wooCommerceItem = this._findNavigationItem('woocommerce');
    if (wooCommerceItem) {
      wooCommerceItem.link = `${this.settings?.wooCommerceStoreUrl}/wp-admin`;
      wooCommerceItem.hidden = (item) => this.settings?.wooCommerceConnected === false;
      if (this.settings?.wooCommerceConnected) {
        wooCommerceItem.link = `${this.settings.wooCommerceStoreUrl}/wp-admin`;
      }
    }
    const shippoItem = this._findNavigationItem('shippo');
    if (shippoItem) {
      shippoItem.hidden = (item) => this.settings?.shippoConnected === false;
    }
    const externalLinksItem = this._findNavigationItem('external');
    if (externalLinksItem) {
      externalLinksItem.hidden = (item) => item.children.every(child => child.hidden(null));
    }
    
    
    this._reBuildAllNavigationItems();
    this._translateAllNavigationItems();

    this._navigation.next(cloneDeep({
      compact: this._compactNavigation,
      default: this._defaultNavigation,
      futuristic: this._futuristicNavigation,
      horizontal: this._horizontalNavigation
    }));
  }

  /**
   * Finds a navigation item by its ID.
   * @param id The ID of the navigation item to find.
   * @returns The navigation item with the specified ID, or null if not found.
   */
  private _findNavigationItem(id: string): FuseNavigationItem {
    const items: FuseNavigationItem[] = this._defaultNavigation
        .map((item) => this._findItem(item, id))
        .filter(item => item);
    return items.length > 0 ? items[0] : null;
  }

  /**
   * Refresh the compact, futuristic, and horizontal navigation items using the default navigation items.
   */
  private _reBuildAllNavigationItems(): void {
    // Fill compact navigation children using the default navigation
    this._compactNavigation.forEach((compactNavItem) => {
        this._defaultNavigation.forEach((defaultNavItem) => {
            if ( defaultNavItem.id === compactNavItem.id )
            {
                compactNavItem.children = cloneDeep(defaultNavItem.children);
            }
        });
    });
    
    // Fill futuristic navigation children using the default navigation
    this._futuristicNavigation.forEach((futuristicNavItem) => {
        this._defaultNavigation.forEach((defaultNavItem) => {
            if ( defaultNavItem.id === futuristicNavItem.id )
            {
                futuristicNavItem.children = cloneDeep(defaultNavItem.children);
            }
        });
    });

    // Fill horizontal navigation children using the default navigation
    this._horizontalNavigation.forEach((horizontalNavItem) => {
        this._defaultNavigation.forEach((defaultNavItem) => {
            if ( defaultNavItem.id === horizontalNavItem.id )
            {
                horizontalNavItem.children = cloneDeep(defaultNavItem.children);
            }
        });
    });
  }

  /**
   * Recursively searches for a navigation item with the given id starting from the provided start item.
   * @param startItem The navigation item to start the search from.
   * @param id The id of the navigation item to find.
   * @returns The navigation item with the given id, or null if it is not found.
   */
  private _findItem(startItem: FuseNavigationItem, id: string): FuseNavigationItem {
    if (startItem.id === id) {
      return startItem;
    }
    if (startItem.children && startItem.children.length > 0) {
      for (const child of startItem.children) {
        const foundItem: FuseNavigationItem = this._findItem(child, id);
        if (foundItem) {
          return foundItem;
        }
      }
    }
    return null;
  }

  /**
   * Translates the navigation items recursively.
   * @param navigation The navigation items to translate.
   * @param prefix The prefix to use for translation keys.
   */
  private _translateNavigationItems(navigation: FuseNavigationItem[], prefix: string): void {
    navigation.forEach((item) => {
        this._translateNavigationItem(item, prefix);
    });
  }

  /**
   * Translates a given navigation item's title, subtitle and children recursively using the provided prefix.
   * @param item The navigation item to translate.
   * @param prefix The prefix to use for translation.
   */
  private _translateNavigationItem(
    item: FuseNavigationItem,
    prefix: string
  ): void {
    item.title = this._translocoService.translate(`${prefix}.${item.id}.title`);
    if (item.subtitle) {
      item.subtitle = this._translocoService.translate(
        `${prefix}.${item.id}.subtitle`
      );
    }
    if (item.children && item.children.length > 0) {
        this._translateNavigationItems(item.children, `${prefix}.${item.id}.children`);
    }
  }

  /**
   * Updates the order notifications badge in the navigation menu based on the number of new orders.
   * If there are no new orders, the badge is removed.
   */
  private _updateOrderNotifications(): void {
    this._orderService
      .countOrders({
        status: ['order_new'],
      }, {
        cache: true,
        expire: CACHE_EXPIRATION_5_MINUTES,
        concatenateResults: false,
        propagate: false,
        forcePropagate: false
      })
      .subscribe((count: number) => {
        const orderItem = this._defaultNavigation.find(
          (item) => item.id === 'orders'
        );
        if (orderItem) {
          if (count > 0) {
            orderItem.badge = {
              title: count.toString(),
              classes: 'px-2 bg-primary text-white rounded-full',
            };
          } else {
            orderItem.badge = null;
          }
        }
      });

    /*
        const statusesRequiringAction: string[] = ['to-be-shipped', 'refund-requested', 'shipping-failed', 'return-shipping-failed'];
        this._orderService.countOrders({
              status: statusesRequiringAction
          }, {
          cache: true,
          expire: CACHE_EXPIRATION_5_MINUTES,
          concatenateResults: false,
          propagate: false,
          forcePropagate: false
        })
        .subscribe((count: number) => {
            this.nRefundRequested = count
            const orderItem = this._defaultNavigation.find(item => item.id === 'orders');
            if (orderItem) {
                if (this.nRefundRequested > 0) {
                    orderItem['badge'] = {
                        title: this.nRefundRequested.toString(),
                        classes: 'px-2 bg-pink-600 text-white rounded-full'
                    }
                } else {
                    delete orderItem['badge'];
                }
            }
        });
        */
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns an observable that emits the current navigation state.
   * @returns An observable that emits the current navigation state.
   */
  get(): Observable<Navigation> {
    this._navigation.next({
      compact: this._compactNavigation,
      default: this._defaultNavigation,
      futuristic: this._futuristicNavigation,
      horizontal: this._horizontalNavigation
    });
    return of(null);
  }

}
