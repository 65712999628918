import { Component } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Params, RouterOutlet } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { environment } from "@env/environment";
import { TranslocoService } from "@jsverse/transloco";
import { first } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { AppFlowService } from "@core/appflow/appflow.service";
import { Crisp } from "crisp-sdk-web";

// Load the Crisp plugin
Crisp.configure(environment.crisp.websiteId, {
  autoload: true,
  sessionMerge: true
});

/**
 * The root component of the application.
 */
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    standalone: true,
    imports    : [
      RouterOutlet
    ],
})
export class AppComponent {

  /**
   * The name of the Transloco read key.
   */
  private _translocoRead: string = 'pwa';

  /**
   * Whether to perform a hard update.
   */
  private _hardUpdate: boolean = false;

  /**
   * Constructs an instance of the AppComponent with the specified dependencies.
   * 
   * @param _swUpdate - The SwUpdate service for checking for and updating the application.
   * @param _translocoService - The TranslocoService for handling translations.
   * @param _activatedRoute - The ActivatedRoute for accessing the current route information.
   * @param _cookieService - The CookieService for managing cookies.
   * @param _snackBar - The MatSnackBar for displaying snack bar notifications.
   * @param _fuseConfirmationService - The FuseConfirmationService for displaying confirmation dialogs.
   * @param _appFlowService - The AppFlowService for managing the application flow.
   */
  constructor(
    private _swUpdate: SwUpdate,
    private _translocoService: TranslocoService,
    private _activatedRoute: ActivatedRoute,
    private _cookieService: CookieService,
    private _snackBar: MatSnackBar,
    private _fuseConfirmationService: FuseConfirmationService,
    private _appFlowService: AppFlowService
  ) { 
    
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * Initializes the component after Angular first displays the data-bound properties and sets the component's input properties.
   * 
   * If `window["dataLayer"]` exists, it pushes an object with the `environment.name` property to it.
   * 
   * Resets the `_appFlowService`.
   * 
   * Checks for updates.
   * 
   * Subscribes to the `_activatedRoute.queryParams` observable and redirects to the initial domain.
   */
  ngOnInit() {
    if (window["dataLayer"]) {
      window["dataLayer"].push({
        env: environment.name
      });
    }
    this._appFlowService.reset();
    this._checkForUpdates();
    this._activatedRoute.queryParams
      .pipe(first())
      .subscribe((params: Params) => {
        this._redirectToInitialDomain();
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Redirects to the initial domain if a redirection hostname is stored in the cookie and it is different from the current hostname.
   * Removes the redirection hostname from the cookie after redirection.
   */
  private _redirectToInitialDomain(): void {
    const currentHostname: string = location.hostname;
    const redirectHostname: string = this._cookieService.get('redirectHostname');
    if (redirectHostname && redirectHostname !== currentHostname) {
      // Remove the redirection hostname from the local storage
      this._cookieService.delete('redirectHostname', '/', 'qart.shop', true, 'Lax');
      const currentUrl: string = location.href;
      const redirectUrl: URL = new URL(currentUrl);
      redirectUrl.hostname = redirectHostname;
      location.replace(redirectUrl);
    }
  }

  /**
   * Checks for updates and prompts the user to update the app if a new version is available.
   * @returns {void}
   */
  private _checkForUpdates(): void {
    if (this._swUpdate.isEnabled) {
      this._swUpdate.versionUpdates.subscribe((event) => {
        if (event.type === "VERSION_DETECTED") {
          console.log(`Downloading new app version: ${event.version.hash}`);
        } else if (event.type === "VERSION_READY") {
          console.log(`Current app version: ${event.currentVersion.hash}`);
          console.log(
            `New app version ready for use: ${event.latestVersion.hash}`
          );
          if (this._hardUpdate) {
            const dialogOpenCookiesPreferences = this._fuseConfirmationService.open({
              title: this._translocoService.translate(`${this._translocoRead}.dialog-update-required.title`),
              message: this._translocoService.translate(`${this._translocoRead}.dialog-update-required.message`),
              icon: {
                show: true,
                name: 'mat_outline:update',
                color: 'primary'
              },
              actions: {
                confirm: {
                  show: true,
                  label: this._translocoService.translate(`${this._translocoRead}.dialog-update-required.btn-confirm`),
                  color: 'warn'
                },
                cancel: {
                  show: false
                }
              },
              dismissible: false
            });
            dialogOpenCookiesPreferences.afterClosed().subscribe((action: any) => {
              if (action === 'confirmed') {
                this._swUpdate.activateUpdate()
                  .then(() => {
                    console.log("New app version activated");
                    let restoredData = {};
                    if (localStorage.getItem('hideOnboardingNextTime') === 'true') {
                      restoredData = {
                        hideOnboardingNextTime: true
                      };
                    }
                    localStorage.clear();
                    for (const key in restoredData) {
                      if (restoredData.hasOwnProperty(key)) {
                        localStorage.setItem(key, restoredData[key]);
                      }
                    }
                    sessionStorage.clear();
                    document.location.reload();
                  });
              }
            });
          }
          else {
            const snackBarRef = this._snackBar.open(
              this._translocoService.translate(`${this._translocoRead}.new-version-available`),
              this._translocoService.translate(`${this._translocoRead}.btn-update`),
              { duration: 10000 }
            );
            snackBarRef.onAction().subscribe(() => {
              this._swUpdate.activateUpdate()
                .then(() => {
                  console.log("New app version activated");
                  let restoredData = {};
                    if (localStorage.getItem('hideOnboardingNextTime') === 'true') {
                      restoredData = {
                        hideOnboardingNextTime: true
                      };
                  } 
                  localStorage.clear();
                  for (const key in restoredData) {
                    if (restoredData.hasOwnProperty(key)) {
                      localStorage.setItem(key, restoredData[key]);
                    }
                  }
                  sessionStorage.clear();
                  document.location.reload();
                });
            });
          }
        } else if (event.type === "VERSION_INSTALLATION_FAILED") {
          console.log(
            `Failed to install app version '${event.version.hash}': ${event.error}`
          );
        }
      });
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

}
