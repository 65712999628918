import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { RouterOutlet } from '@angular/router';

import { FuseLoadingBarComponent } from '../../../../@fuse/components/loading-bar/loading-bar.component';

/**
 * Component for the empty layout.
 */
@Component({
    selector: 'empty-layout',
    templateUrl: './empty.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FuseLoadingBarComponent, RouterOutlet]
})
export class EmptyLayoutComponent implements OnDestroy {
    /**
     * Subject that emits when the component has been destroyed.
     */
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor.
     */
    constructor() {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * Lifecycle hook that is called when the component is destroyed.
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Method that is called when a component is activated.
     * 
     * @param event - The event that triggered the activation.
     * @param outlet - The outlet that the component is being activated in.
     */
    onActivate(event, outlet) {
        if (window["dataLayer"]) {
            window["dataLayer"].push({
                page_name: outlet.activatedRoute.snapshot.data?.pageName
            });
        }
    }

}
