import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';

/**
 * AuthGuard function that implements the CanActivateFn or CanActivateChildFn interface.
 * It checks the authentication status and redirects the user to the sign-in page if not authenticated.
 * @param route - The activated route snapshot.
 * @param state - The router state snapshot.
 * @returns An Observable that emits a boolean indicating whether the user is authenticated or not.
 */
export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    
    // Check the authentication status
    return authService.check().pipe(
        switchMap((authenticated) =>
        {
            // If the user is not authenticated...
            if (!authenticated) {

                // Redirect to the sign-in page
                router.navigate(['sign-in'], { queryParams: { redirectUrl } });

                // Prevent the access
                return of(false);
            }

            // Allow the access
            return of(true);
        }),
    );
};

